import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import Button from "react-bootstrap/Button";
import "./EzymoteUserDetails.css";

const baseUrl = process.env.REACT_APP_API_URL;

const EzymoteUserDetails = () => {
  const { user } = useAuthContext();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const navigateToEzymoteSensorBox = (deviceIDDetails) => {
    navigate("/EzymoteDeviceDetail", {
      state: { id: { deviceIDDetails } },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const apis = `${baseUrl}/api/deviceAssigned/${user.email}`;
      try {
        const response = await fetch(apis, {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const data1 = data[0];
        setUsers(data1.deviceIDDetails);
      } catch (error) {
        setError("Error fetching data. Please try again later.");
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="error">{error}</div>;
  if (users.length === 0)
    return <div className="no-devices">No devices found.</div>;

  return (
    <div className="parent">
      {users.map((deviceIDDetails, index) => (
        <div className="child" key={index}>
          <div className="cardSensor-bigcontainer">
            <br />
            <hr />
            <b>DeviceID: {deviceIDDetails}</b>
            <br />
            <div className="child44">
              <div className="B1">
                <Button
                  variant="success"
                  onClick={() => navigateToEzymoteSensorBox(deviceIDDetails)}
                >
                  View Switches
                </Button>
              </div>
            </div>
            <hr />
          </div>
        </div>
      ))}
    </div>
  );
};

export default EzymoteUserDetails;
