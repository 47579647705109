// // import React from 'react'
// import { Bar } from 'react-chartjs-2'
import React, { useState, useRef, useEffect } from "react";

import Typography from "@mui/material/Typography";

// import { useMemo } from "react";
// import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

// // import React from "react";
// import ReactDOM from "react-dom";
// // import React from "react";
// // import ReactDOM from "react-dom";
// // import ReactDOM from '../react-dom';
// // import './index.css';
// import "../components/styles.css";
// import socketIO from "socket.io-client";

// // import { useEffect, useState }from 'react'
// import { useWorkoutsContext } from "../hooks/useWorkoutsContext"
// import { useAuthContext } from "../hooks/useAuthContext"
// import { useSensorDatasContext } from "../hooks/useSensorDatasContext"

// // components
// import WorkoutDetails from '../components/WorkoutDetails'
// import WorkoutForm from '../components/WorkoutForm'
// import SensorDataDetails from '../components/SensorDataDetails'

// import { io } from 'socket.io-client';
// import { setSeconds } from 'date-fns'

// // import BarChart from '../components/BarChart'

// import { useLocation, useSearchParams } from "react-router-dom";

import { useLocation } from "react-router-dom";

// import React from "react";
// import { useForm } from "react-hook-form";

// import '../index.css';

// import RegisterForm from "../components/RegisterForm.js";
import BioflySensorBox from "../components/bioflySensorBox.js";

import ShmSensorBox from "../components/ShmSensorBox.js";

import DynamicChart from "../components/DynamicChart.js";

import { Route, Link, Routes, useNavigate } from "react-router-dom";

import { useAuthContext } from "../hooks/useAuthContext.js";

import moment from "moment";
import { ClassNames } from "@emotion/react";

const baseUrl = process.env.REACT_APP_API_URL;

// var labels123 = ["January", "February", "March", "April", "May", "June"];
var temperatureChartData = {
  // labels: ["January", "February", "March", "April", "May", "June"],
  labels: [],
  datasets: [
    // {
    //   label: "Sales",
    //   data: [21, 35, 75, 51, 41, 47],
    //   fill: false, // for Line chart
    //   backgroundColor: "#FB8833",
    //   borderColor: "#FB8833", // for Line chart
    // },
    {
      label: "Temperature",
      data: [],
      fill: false, // for Line chart
      backgroundColor: "#17A8F5",
      borderColor: "#17A8F5", // for Line chart
    },
  ],
};

var vibrationChartData = {
  // labels: ["January", "February", "March", "April", "May", "June"],
  labels: [],
  datasets: [
    // {
    //   label: "Sales",
    //   data: [21, 35, 75, 51, 41, 47],
    //   fill: false, // for Line chart
    //   backgroundColor: "#FB8833",
    //   borderColor: "#FB8833", // for Line chart
    // },
    {
      label: "Vibration",
      data: [],
      fill: false, // for Line chart
      backgroundColor: "#17A8F5",
      borderColor: "#17A8F5", // for Line chart
    },
  ],
};

var tiltMeterChartData = {
  // labels: ["January", "February", "March", "April", "May", "June"],
  labels: [],
  datasets: [
    // {
    //   label: "Sales",
    //   data: [21, 35, 75, 51, 41, 47],
    //   fill: false, // for Line chart
    //   backgroundColor: "#FB8833",
    //   borderColor: "#FB8833", // for Line chart
    // },
    {
      label: "TiltMeter",
      data: [],
      fill: false, // for Line chart
      backgroundColor: "#17A8F5",
      borderColor: "#17A8F5", // for Line chart
    },
  ],
};

var accelerometerChartData = {
  // labels: ["January", "February", "March", "April", "May", "June"],
  labels: [],
  datasets: [
    // {
    //   label: "Sales",
    //   data: [21, 35, 75, 51, 41, 47],
    //   fill: false, // for Line chart
    //   backgroundColor: "#FB8833",
    //   borderColor: "#FB8833", // for Line chart
    // },
    {
      label: "Accelerometer",
      data: [],
      fill: false, // for Line chart
      backgroundColor: "#17A8F5",
      borderColor: "#17A8F5", // for Line chart
    },
  ],
};

const ShmDeviceCharts = () => {
  const { user } = useAuthContext();

  const { state } = useLocation();
  const { id } = state;
  // console.log("id............");
  // console.log(id);

  // console.log("user:");
  // console.log(user);

  //   console.log("deviceID:");
  // console.log(id.number);

  // console.log("deviceID123:");
  // console.log(id.number);

  // console.log(id.state)

  // const location = useLocation();

  // console.log("location:")
  // console.log(location)

  // const [searchparams] = useSearchParams();

  // console.log(searchparams.get("id"));

  // console.log("deviceID:")
  // console.log(deviceID)

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [items, setItems] = useState();
  const [items2, setItems2] = useState();
  const [items3, setItems3] = useState();
  const [items4, setItems4] = useState();

  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    // console.log('Only once execution here only...');

    // console.log(id);

    // console.log("deviceIDDetails: ", json1.deviceIDDetails);
    // console.log("role: ", json1.role);
    // console.log("deviceType: ", json1.deviceType);

    // setUsers(json1.deviceIDDetails);

    // console.log("users1", props.users1);

    // const apis = "/api/deviceAssigned/" + user.email;

    // fetch(apis, {
    //   headers: { Authorization: `Bearer ${user.token}` },
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     const data1 = data[0];
    //     // console.log("data:", data1);
    //     // console.log("data.role:", data1.role);
    //     console.log("data.deviceIDDetails:", data1.deviceIDDetails);
    //     // setItems(data);
    //     setUsers(data1.deviceIDDetails);
    //   });

    // const apis = "/api/sensorDatas/shm/date/" + id.deviceIDDetails + "/" + "1";

    const apis =
      `${baseUrl}/api/sensorDatas/shm/date/` + id.deviceIDDetails + "/" + "1";

    // console.log("apis:", apis);

    fetch(apis, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("data:", data);

        const length = data.length;
        // console.log("length:");
        // console.log(length);

        // console.log("json11111:");
        // console.log(json1[0]._id);

        // labels = [];
        // values = [];
        for (var i = 0; i < length; i++) {
          const date = moment.unix(data[i].timestamp).format("DD/MM/YYYY");
          const time = moment.unix(data[i].timestamp).format("hh:mm:ss");
          temperatureChartData.labels[i] = time + " " + date;

          // temperatureChartData.labels[i] = data[i].createdAt;
          temperatureChartData.datasets[0].data[i] = data[i].temperature;

          vibrationChartData.labels[i] = time + " " + date;
          vibrationChartData.datasets[0].data[i] = data[i].vibration;

          tiltMeterChartData.labels[i] = time + " " + date;
          tiltMeterChartData.datasets[0].data[i] = data[i].tiltMeter;

          accelerometerChartData.labels[i] = time + " " + date;
          accelerometerChartData.datasets[0].data[i] = data[i].accelerometer;
        }

        // console.log("temperatureChartData.labels", temperatureChartData.labels);
        // console.log("temperatureChartData.datasets", temperatureChartData.datasets);

        setItems2(vibrationChartData);
        setItems3(tiltMeterChartData);
        setItems4(accelerometerChartData);
        setItems(temperatureChartData);
      });
  }, []);

  const divStyles = {
    // backgroundColor: "teal",
    // padding: 10,
    marginTop: 60,
    // marginLeft: 100,
    // height: 400,
    // width: 400,
  };

  const divStyles1 = {
    // backgroundColor: "teal",
    // padding: 10,
    // marginTop: 60,

    // marginLeft: 100,
    height: 280,
    width: 420,
  };

  return (
    // <div class="flex-container">

    <div className="parent">
      {/* <DynamicChart data={labelsstate} /> */}

      {/* <div>{items && <DynamicChart items={items} />}</div>

      <div>{items2 && <DynamicChart items={items2} />}</div>

      <div>{items3 && <DynamicChart items={items3} />}</div> */}

      <div className="child1">
        <div className="cardSensor-bigcontainer1">
          {items && <DynamicChart items={items} />}
        </div>
      </div>

      <div className="child1">
        <div className="cardSensor-bigcontainer1">
          {items2 && <DynamicChart items={items2} />}
        </div>
      </div>

      <div className="child1">
        <div className="cardSensor-bigcontainer1">
          {items3 && <DynamicChart items={items3} />}
        </div>
      </div>

      <div className="child1">
        <div className="cardSensor-bigcontainer1">
          {items4 && <DynamicChart items={items4} />}
        </div>
      </div>

      {/* <div>1</div>
      <div>2</div>
      <div>1</div>
      <div>2</div>
      <div>1</div>
      <div>2</div>
      <div>1</div>
      <div>2</div> */}
      {/* <div>3</div>
      <div>4</div> */}

      {/* <div>saroj1</div>
      <div>saroj2</div> */}
      {/* // <div id="myDIV"> */}
      {/* <div id="mymain"> */}
      {/* <> */}
      {/* <div>    view url search params </div> */}
      {/* id is {searchparams.state.id} */}
      {/* id is {location.state.deviceId} */}
      {/* </> */}
      {/* <button onClick={goBack}>Back</button> */}
      {/* <div id="myDIV"> */}
      {/* <div>red div</div>
          <div>blue div</div>
          <div>yellow div</div>
          <div>pink div</div>
        </div> */}
      {/* </div> */}
      {/* <div className="chartBox">saroj1</div>

      <div className="chartBox">saroj2</div> */}
      {/* <div className="chartBox">{items && <DynamicChart items={items} />}</div>

      <div className="chartBox">{items2 && <DynamicChart items={items2} />}</div> */}
      {/* {items3 && <DynamicChart items={items3} />}
      {items4 && <DynamicChart items={items4} />} */}
      {/* <BioflySensorBox deviceID={id.number} /> */}
      {/* <ShmSensorBox deviceID={id.number} /> */}
      {/* <BioflyBox deviceID={deviceIDName}/> */}
      {/* </div> */}
    </div>
  );
};

export default ShmDeviceCharts;
