// // import React from 'react'
// import { Bar } from 'react-chartjs-2'
// import React, { useState, useEffect } from "react";

// import { useMemo } from "react";
// import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

// // import React from "react";
// import ReactDOM from "react-dom";
// // import React from "react";
// // import ReactDOM from "react-dom";
// // import ReactDOM from '../react-dom';
// // import './index.css';
// import "../components/styles.css";
// import socketIO from "socket.io-client";

// // import { useEffect, useState }from 'react'
// import { useWorkoutsContext } from "../hooks/useWorkoutsContext"
// import { useAuthContext } from "../hooks/useAuthContext"
// import { useSensorDatasContext } from "../hooks/useSensorDatasContext"

// // components
// import WorkoutDetails from '../components/WorkoutDetails'
// import WorkoutForm from '../components/WorkoutForm'
// import SensorDataDetails from '../components/SensorDataDetails'

// import { io } from 'socket.io-client';
// import { setSeconds } from 'date-fns'

// // import BarChart from '../components/BarChart'

import React from "react";
// import { useForm } from "react-hook-form";

// import '../index.css';
 

// import RegisterForm from "../components/RegisterForm.js";

const Support = () => {
  return (
    <div>
 

      {/* <RegisterForm /> */}
    </div>
  );
};

export default Support;
