// // import React from 'react'
// import { Bar } from 'react-chartjs-2'
// import React, { useState, useEffect } from "react";

// import { useMemo } from "react";
// import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

// // import React from "react";
// import ReactDOM from "react-dom";
// // import React from "react";
// // import ReactDOM from "react-dom";
// // import ReactDOM from '../react-dom';
// // import './index.css';
// import "../components/styles.css";
// import socketIO from "socket.io-client";

// // import { useEffect, useState }from 'react'
// import { useWorkoutsContext } from "../hooks/useWorkoutsContext"
// import { useAuthContext } from "../hooks/useAuthContext"
// import { useSensorDatasContext } from "../hooks/useSensorDatasContext"

// // components
// import WorkoutDetails from '../components/WorkoutDetails'
// import WorkoutForm from '../components/WorkoutForm'
// import SensorDataDetails from '../components/SensorDataDetails'

// import { io } from 'socket.io-client';
// import { setSeconds } from 'date-fns'

// // import BarChart from '../components/BarChart'

// import { useLocation, useSearchParams } from "react-router-dom";

import { useLocation } from "react-router-dom";

import React from "react";
// import { useForm } from "react-hook-form";

// import '../index.css';

// import RegisterForm from "../components/RegisterForm.js";
// import BioflySensorBox from "../components/bioflySensorBox.js";

import ShmSensorBox from "../components/ShmSensorBox.js";

import { useNavigate } from "react-router-dom";

// import { useAuthContext } from "../hooks/useAuthContext.js";

const ShmDeviceDetail = () => {
  // const { user } = useAuthContext();

  const { state } = useLocation();
  const { id } = state;
  // console.log("id............");
  // console.log(id);

  // console.log("user:");
  // console.log(user);

  //   console.log("deviceID:");
  // console.log(id.number);

  // console.log("deviceID:");
  // console.log(id.number);

  // console.log(id.state)

  // const location = useLocation();

  // console.log("location:")
  // console.log(location)

  // const [searchparams] = useSearchParams();

  // console.log(searchparams.get("id"));

  // console.log("deviceID:")
  // console.log(deviceID)

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="parent">
      <button type="button" class="btn btn-info" onClick={goBack}>
        Back
      </button>

      {/* <BioflySensorBox deviceID={id.number} /> */}

      <ShmSensorBox deviceID={id.deviceIDDetails} />

      {/* <BioflyBox deviceID={deviceIDName}/> */}
    </div>
  );
};

export default ShmDeviceDetail;
