import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { useAuthContext } from "../hooks/useAuthContext";
import moment from "moment";

import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBTextArea,
  MDBBtn,
  MDBContainer,
} from "mdb-react-ui-kit";

import { DateRangePicker, Stack } from "rsuite";
import "../components/DateRangePickerstyles.css";

import { faBus, faCarSide } from "@fortawesome/free-solid-svg-icons";

import VehicleHistryTrack from "./VehicleHistryTrack.js";

const baseUrl = process.env.REACT_APP_API_URL;

const containerStyle = {
  // width: "vw-100",
  // height: "100%",
  width: "1200px",
  height: "600px",
};

const center = {
  lat: 20.246855,
  lng: 85.840445,
};

// import DynamicChart from "./DynamicChart";/

// // var labels123 = ["January", "February", "March", "April", "May", "June"];
// var temperatureChartData = {
//   // labels: ["January", "February", "March", "April", "May", "June"],
//   labels: [],
//   datasets: [
//     // {
//     //   label: "Sales",
//     //   data: [21, 35, 75, 51, 41, 47],
//     //   fill: false, // for Line chart
//     //   backgroundColor: "#FB8833",
//     //   borderColor: "#FB8833", // for Line chart
//     // },
//     {
//       label: "Temperature",
//       data: [],
//       fill: false, // for Line chart
//       backgroundColor: "#17A8F5",
//       borderColor: "#17A8F5", // for Line chart
//     },
//   ],
// };

// var vibrationChartData = {
//   // labels: ["January", "February", "March", "April", "May", "June"],
//   labels: [],
//   datasets: [
//     // {
//     //   label: "Sales",
//     //   data: [21, 35, 75, 51, 41, 47],
//     //   fill: false, // for Line chart
//     //   backgroundColor: "#FB8833",
//     //   borderColor: "#FB8833", // for Line chart
//     // },
//     {
//       label: "Vibration",
//       data: [],
//       fill: false, // for Line chart
//       backgroundColor: "#17A8F5",
//       borderColor: "#17A8F5", // for Line chart
//     },
//   ],
// };

// var tiltMeterChartData = {
//   // labels: ["January", "February", "March", "April", "May", "June"],
//   labels: [],
//   datasets: [
//     // {
//     //   label: "Sales",
//     //   data: [21, 35, 75, 51, 41, 47],
//     //   fill: false, // for Line chart
//     //   backgroundColor: "#FB8833",
//     //   borderColor: "#FB8833", // for Line chart
//     // },
//     {
//       label: "TiltMeter",
//       data: [],
//       fill: false, // for Line chart
//       backgroundColor: "#17A8F5",
//       borderColor: "#17A8F5", // for Line chart
//     },
//   ],
// };

// var accelerometerChartData = {
//   // labels: ["January", "February", "March", "April", "May", "June"],
//   labels: [],
//   datasets: [
//     // {
//     //   label: "Sales",
//     //   data: [21, 35, 75, 51, 41, 47],
//     //   fill: false, // for Line chart
//     //   backgroundColor: "#FB8833",
//     //   borderColor: "#FB8833", // for Line chart
//     // },
//     {
//       label: "Accelerometer",
//       data: [],
//       fill: false, // for Line chart
//       backgroundColor: "#17A8F5",
//       borderColor: "#17A8F5", // for Line chart
//     },
//   ],
// };

const EzytrackSensorBox = (props) => {
  // let [sensordata, setsensordata] = useState(
  //   '{"deviceID":"869523050076489","deviceType":"shm","csq":"21","batVoltage":"11.7","fiberOptic":"20","accelerometer":"70","vibration":"0.00","loadCell":"0.00","strainGauge":"0","inclinoMeter":"0","tiltMeter":"0","acousticEmission":"124","temperature":"22","warning":"0","error":"0","timestamp":"1684133370"}'
  // );

  // var [labelsstate, setlabelsstate] = React.useState(temperatureChartData);

  const [value, setValue] = React.useState([]);

  const { user } = useAuthContext();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);

    map.setZoom(14);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  // const deviceID = props.deviceID;

  // const [items, setItems] = useState();

  // const [items2, setItems2] = useState();
  // const [items3, setItems3] = useState();
  // const [items4, setItems4] = useState();

  const checkvalue = () => {
    // console.log("fromDate:");
    // console.log(value[0]);
    // console.log("toDate:");
    // console.log(value[1]);
    // let dateString0 = value[0];
    // let fromDate = Date.parse(dateString0);
    // // console.log("fromDate:");
    // // console.log(fromDate);
    // let dateString1 = value[1];
    // let toDate = Date.parse(dateString1);
    // // console.log("toDate:");
    // // console.log(toDate);
    // setfromDateString(fromDate);
    // settoDateString(toDate);
    // // let dateString1 = value[0];
    // // let date = new Date(dateString1);
    // // let result1 = date.getTime();
    // // console.log(result1);
    // // const apis =
    // //   `${baseUrl}/api/sensorDatas/biofly/days/7/` + id.deviceIDDetails;
    // const apis =
    //   `${baseUrl}/api/sensorDatas/biofly/datetimerange/869523050076489/` +
    //   fromDate +
    //   `/` +
    //   toDate;
    // // /biofly/datetimerange/:deviceID/:fromdate/:todate
    // // console.log("apis:", apis);
    // // console.log("apis:", apis);
    // fetch(apis, {
    //   headers: { Authorization: `Bearer ${user.token}` },
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     // console.log("data:", data);
    //     setItems(data);
    //   });
  };

  useEffect(() => {
    // const apis = "/api/deviceAssigned/" + useremail;
    // // console.log("apis:")
    // // console.log(apis)
    // const response = await fetch(apis, {
    //   headers: { Authorization: `Bearer ${user.token}` },
    // });
    // const apis = "/api/sensorDatas/shm/date/" + deviceID + "/" + "1";
    // console.log("apis:", apis);
    // fetch(apis, {
    //   headers: { Authorization: `Bearer ${user.token}` },
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     console.log("data:", data);
    //     const length = data.length;
    //     console.log("length:");
    //     console.log(length);
    //     // console.log("json11111:");
    //     // console.log(json1[0]._id);
    //     // labels = [];
    //     // values = [];
    //     for (var i = 0; i < length; i++) {
    //       const date = moment.unix(data[i].timestamp).format("DD/MM/YYYY");
    //       const time = moment.unix(data[i].timestamp).format("hh:mm:ss");
    //       temperatureChartData.labels[i] = time + " " + date;
    //       // temperatureChartData.labels[i] = data[i].createdAt;
    //       temperatureChartData.datasets[0].data[i] = data[i].temperature;
    //       vibrationChartData.labels[i] = time + " " + date;
    //       vibrationChartData.datasets[0].data[i] = data[i].vibration;
    //       tiltMeterChartData.labels[i] = time + " " + date;
    //       tiltMeterChartData.datasets[0].data[i] = data[i].tiltMeter;
    //       accelerometerChartData.labels[i] = time + " " + date;
    //       accelerometerChartData.datasets[0].data[i] = data[i].accelerometer;
    //     }
    //     // console.log("temperatureChartData.labels", temperatureChartData.labels);
    //     // console.log("temperatureChartData.datasets", temperatureChartData.datasets);
    //     setItems2(vibrationChartData);
    //     setItems3(tiltMeterChartData);
    //     setItems4(accelerometerChartData);
    //     setItems(temperatureChartData);
    //   });
  }, []);

  // const [chartData, setChartData] = useState({});
  // const [employeeSalary, setEmployeeSalary] = useState([]);
  // const [employeeAge, setEmployeeAge] = useState([]);

  // const Chart = (deviceID) => {
  //   const apis = "/api/sensorDatas/biofly/date/" + deviceID + "/" + "1";

  //   let empSal = [];
  //   let empAge = [];

  //   fetch(apis, {
  //     headers: { Authorization: `Bearer ${user.token}` },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log("data: ", data);
  //       // setItems(data)

  //       const length = data.length;
  //       // console.log("length:");
  //       // console.log(length);

  //       // console.log("json11111:");
  //       // console.log(json1[0]._id);

  //       // labels = [];
  //       // values = [];
  //       for (var i = 0; i < length; i++) {
  //         const date = moment.unix(data[i].timestamp).format("DD/MM/YYYY");
  //         const time = moment.unix(data[i].timestamp).format("hh:mm:ss");

  //         data.labels[i] = time + date;
  //         data.datasets[0].data[i] = data[i].temperature;

  //         empSal.push(data.datasets[0].data[i]);
  //         empAge.push(data.labels[i]);
  //       }

  //       console.log("data.labels", data.labels);
  //       console.log("data.datasets", data.datasets);

  //       setlabelsstate(data);
  //       // setChartData({
  //       //   labels: empAge,
  //       //   datasets: [
  //       //     {
  //       //       label: "level of thicceness",
  //       //       data: empSal,
  //       //       backgroundColor: [
  //       //         "rgba(255, 99, 132, 0.2)",
  //       //         "rgba(54, 162, 235, 0.2)",
  //       //         "rgba(255, 206, 86, 0.2)",
  //       //         "rgba(75, 192, 192, 0.2)",
  //       //         "rgba(153, 102, 255, 0.2)",
  //       //         "rgba(255, 159, 64, 0.2)",
  //       //         "rgba(255, 99, 132, 0.2)",
  //       //         "rgba(54, 162, 235, 0.2)",
  //       //         "rgba(255, 206, 86, 0.2)",
  //       //         "rgba(75, 192, 192, 0.2)",
  //       //         "rgba(153, 102, 255, 0.2)",
  //       //         "rgba(255, 159, 64, 0.2)",
  //       //         "rgba(255, 99, 132, 0.2)",
  //       //         "rgba(54, 162, 235, 0.2)",
  //       //         "rgba(255, 206, 86, 0.2)",
  //       //         "rgba(75, 192, 192, 0.2)",
  //       //         "rgba(153, 102, 255, 0.2)",
  //       //         "rgba(255, 159, 64, 0.2)",
  //       //         "rgba(255, 99, 132, 0.2)",
  //       //         "rgba(54, 162, 235, 0.2)",
  //       //         "rgba(255, 206, 86, 0.2)",
  //       //         "rgba(75, 192, 192, 0.2)",
  //       //         "rgba(153, 102, 255, 0.2)",
  //       //         "rgba(255, 159, 64, 0.2)",
  //       //       ],
  //       //       borderColor: [
  //       //         "rgba(255, 99, 132, 1)",
  //       //         "rgba(54, 162, 235, 1)",
  //       //         "rgba(255, 206, 86, 1)",
  //       //         "rgba(75, 192, 192, 1)",
  //       //         "rgba(153, 102, 255, 1)",
  //       //         "rgba(255, 159, 64, 1)",
  //       //         "rgba(255, 99, 132, 1)",
  //       //         "rgba(54, 162, 235, 1)",
  //       //         "rgba(255, 206, 86, 1)",
  //       //         "rgba(75, 192, 192, 1)",
  //       //         "rgba(153, 102, 255, 1)",
  //       //         "rgba(255, 159, 64, 1)",
  //       //         "rgba(255, 99, 132, 1)",
  //       //         "rgba(54, 162, 235, 1)",
  //       //         "rgba(255, 206, 86, 1)",
  //       //         "rgba(75, 192, 192, 1)",
  //       //         "rgba(153, 102, 255, 1)",
  //       //         "rgba(255, 159, 64, 1)",
  //       //         "rgba(255, 99, 132, 1)",
  //       //         "rgba(54, 162, 235, 1)",
  //       //         "rgba(255, 206, 86, 1)",
  //       //         "rgba(75, 192, 192, 1)",
  //       //         "rgba(153, 102, 255, 1)",
  //       //         "rgba(255, 159, 64, 1)",
  //       //       ],
  //       //       borderWidth: 1,
  //       //     },
  //       //   ],
  //       // });
  //     });

  //   // .then((res) => {
  //   //   console.log(res);
  //   //   // for (const dataObj of res.data.data) {
  //   //   // empSal.push(parseInt(dataObj.employee_salary));
  //   //   // empAge.push(parseInt(dataObj.employee_age));
  //   //   // }

  //   //   // const length1 = res.data.length;
  //   //   // console.log("length1:");
  //   //   // console.log(length1);

  //   //   // console.log("json11111:");
  //   //   // console.log(json1[0]._id);

  //   //   // labels = [];
  //   //   // values = [];
  //   //   // for (var i = 0; i < length; i++) {
  //   //   //   const date = moment.unix(json1[i].timestamp).format("DD/MM/YYYY");
  //   //   //   const time = moment.unix(json1[i].timestamp).format("hh:mm:ss");

  //   //   //   data.labels[i] = time + date;
  //   //   //   data.datasets[0].data[i] = json1[i].temperature;
  //   //   // }

  //   //   setChartData({
  //   //     labels: empAge,
  //   //     datasets: [
  //   //       {
  //   //         label: "level of thicceness",
  //   //         data: empSal,
  //   //         backgroundColor: [
  //   //           "rgba(255, 99, 132, 0.2)",
  //   //           "rgba(54, 162, 235, 0.2)",
  //   //           "rgba(255, 206, 86, 0.2)",
  //   //           "rgba(75, 192, 192, 0.2)",
  //   //           "rgba(153, 102, 255, 0.2)",
  //   //           "rgba(255, 159, 64, 0.2)",
  //   //           "rgba(255, 99, 132, 0.2)",
  //   //           "rgba(54, 162, 235, 0.2)",
  //   //           "rgba(255, 206, 86, 0.2)",
  //   //           "rgba(75, 192, 192, 0.2)",
  //   //           "rgba(153, 102, 255, 0.2)",
  //   //           "rgba(255, 159, 64, 0.2)",
  //   //           "rgba(255, 99, 132, 0.2)",
  //   //           "rgba(54, 162, 235, 0.2)",
  //   //           "rgba(255, 206, 86, 0.2)",
  //   //           "rgba(75, 192, 192, 0.2)",
  //   //           "rgba(153, 102, 255, 0.2)",
  //   //           "rgba(255, 159, 64, 0.2)",
  //   //           "rgba(255, 99, 132, 0.2)",
  //   //           "rgba(54, 162, 235, 0.2)",
  //   //           "rgba(255, 206, 86, 0.2)",
  //   //           "rgba(75, 192, 192, 0.2)",
  //   //           "rgba(153, 102, 255, 0.2)",
  //   //           "rgba(255, 159, 64, 0.2)",
  //   //         ],
  //   //         borderColor: [
  //   //           "rgba(255, 99, 132, 1)",
  //   //           "rgba(54, 162, 235, 1)",
  //   //           "rgba(255, 206, 86, 1)",
  //   //           "rgba(75, 192, 192, 1)",
  //   //           "rgba(153, 102, 255, 1)",
  //   //           "rgba(255, 159, 64, 1)",
  //   //           "rgba(255, 99, 132, 1)",
  //   //           "rgba(54, 162, 235, 1)",
  //   //           "rgba(255, 206, 86, 1)",
  //   //           "rgba(75, 192, 192, 1)",
  //   //           "rgba(153, 102, 255, 1)",
  //   //           "rgba(255, 159, 64, 1)",
  //   //           "rgba(255, 99, 132, 1)",
  //   //           "rgba(54, 162, 235, 1)",
  //   //           "rgba(255, 206, 86, 1)",
  //   //           "rgba(75, 192, 192, 1)",
  //   //           "rgba(153, 102, 255, 1)",
  //   //           "rgba(255, 159, 64, 1)",
  //   //           "rgba(255, 99, 132, 1)",
  //   //           "rgba(54, 162, 235, 1)",
  //   //           "rgba(255, 206, 86, 1)",
  //   //           "rgba(75, 192, 192, 1)",
  //   //           "rgba(153, 102, 255, 1)",
  //   //           "rgba(255, 159, 64, 1)",
  //   //         ],
  //   //         borderWidth: 1,
  //   //       },
  //   //     ],
  //   //   });
  //   // })
  //   // .catch((err) => {
  //   //   console.log(err);
  //   // });
  // };

  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    // console.log("ShmSensorBox Only once execution here only...");

    // fetchSensorDataDetail(deviceID);
    // fetchSensorDataDetail1(deviceID);
  }, []);

  // useEffect(() => {
  //   let interval = setInterval(() => {
  //     // const res = await fetch(`https://jsonplaceholder.typicode.com/posts`);
  //     // const json = fetchSensorDataDetail(deviceID);
  //     // fetchSensorDataDetail(deviceID);
  //     // fetchSensorDataDetail1(deviceID);
  //   }, 3000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  return isLoaded ? (
    <section className="border text-center mb-4">
      {/* <SimpleMap /> */}

      <div className="row">
        <div className="col-lg-4">
          {/* <div class="card w-50 p-3"> */}
          {/* <div class="card-body">
            <h5 class="card-title">Card title</h5>
          </div> */}
          {/* <div class="ratio ratio-1x1">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d356933.8714888409!2d10.413661869378636!3d45.65994086120074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4781eca8aec020b9%3A0x91dcf07c1c969bb8!2sGarda!5e0!3m2!1spl!2spl!4v1672244147501!5m2!1spl!2spl"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div> */}
          {/* </div> */}

          {/* <MDBContainer fluid>...</MDBContainer> */}

          <form>
            <div className="row">
              <div className="col-md-12">
                {/* <div className="mb-4">Select a Date range to history path</div> */}

                <p class="fw-bolder">
                  Select a Date range to check path history
                </p>
                <br></br>

                {/* <Stack spacing={25}>
                  <DateRangePicker
                    value={value}
                    onChange={setValue}
                    showMeridian
                    format="yyyy-MM-dd HH:mm:ss"
                    onExited={checkvalue}
                    defaultCalendarValue={[
                      new Date("2024-01-01 00:00:00"),
                      new Date("2024-02-01 23:59:59"),
                    ]}
                  />
                </Stack> */}

                <MDBRow className="mb-4">
                  <DateRangePicker
                    value={value}
                    onChange={setValue}
                    showMeridian
                    format="yyyy-MM-dd HH:mm:ss"
                    onExited={checkvalue}
                    defaultCalendarValue={[
                      new Date("2024-01-01 00:00:00"),
                      new Date("2024-02-01 23:59:59"),
                    ]}
                  />
                </MDBRow>
                <MDBBtn color="info" className="mb-4">
                  Locate now
                </MDBBtn>

                <section className="border text-center mb-4">
                  <p class="text-uppercase">Tracking object details</p>

                  {/* <p class="fw-bolder">Tracking object details</p> */}
                  <br></br>
                  <p class="fw-bolder">Device Status: online/offline</p>
                  <br></br>
                  <p class="fw-bolder">Stop Since: 4 hours 30 Minutes</p>
                  <br></br>

                  <MDBBtn color="danger" className="mb-4">
                    Start fast Tracking
                  </MDBBtn>

                  <MDBBtn color="success" className="mb-4">
                    Stop fast Tracking
                  </MDBBtn>
                </section>

                {/* <MDBRow className="mb-4">
                  <MDBCol>
                    <MDBInput label="First name" id="form3FirstName" />
                  </MDBCol>
                  <MDBCol>
                    <MDBInput label="Email Address" id="form3Email" />
                  </MDBCol>
                </MDBRow>
                <MDBInput
                  type="text"
                  label="Subject"
                  id="form3Subject"
                  v-model="form3Subject"
                  wrapperClass="mb-4"
                />
                <MDBTextArea
                  label="Message"
                  id="form3Textarea"
                  wrapperClass="mb-4"
                />
                <MDBBtn color="primary" className="mb-4">
                  {" "}
                  Send{" "}
                </MDBBtn> */}
              </div>
            </div>
          </form>
        </div>
        <div className="col-lg-8">
          {/* <div class="card w-50 p-3"> */}
          <div class="card-body">
            {/* <h5 class="card-title">Card title</h5> */}
          </div>
          <div class="ratio ratio-16x9">
            <VehicleHistryTrack />
          </div>
          {/* </div> */}
        </div>
      </div>
    </section>
  ) : (
    <></>
  );
};

export default EzytrackSensorBox;
