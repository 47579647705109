import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import ResponsiveAppBar from "./components/ResponsiveAppBar";

// Pages
import Home from "./pages/Home";
import LoginPage from "./pages/LoginPage";
import Signup from "./pages/Signup";
import Admin from "./pages/Admin";
import Graphs from "./pages/Graphs";
import Reports from "./pages/Reports";
import Support from "./pages/Support";
import ContactUs from "./pages/ContactUs";
import Notification from "./pages/Notification";
import BioflyDeviceDetail from "./pages/BioflyDeviceDetail";
import BioflyUserDetails from "./pages/BioflyUserDetails";
import BioflyDeviceCharts from "./pages/BioflyDeviceCharts";
import BioflyDataInTables from "./pages/BioflyDataInTables";
import EzytrackDeviceDetail from "./pages/EzytrackDeviceDetail";
import EzytrackUserDetails from "./pages/EzytrackUserDetails";
import EzymoteDeviceDetail from "./pages/EzymoteDeviceDetail";
import EzymoteUserDetails from "./pages/EzymoteUserDetails";
import ShmUserDetails from "./pages/ShmUserDetails";
import ShmDeviceCharts from "./pages/ShmDeviceCharts";
import ShmDeviceDetail from "./pages/ShmDeviceDetail";

// Protected Route Component
const ProtectedRoute = ({ user, children }) => {
  return user ? children : <Navigate to="/login" />;
};

function App() {
  const { user } = useAuthContext();

  return (
    <BrowserRouter>
      <ResponsiveAppBar />
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute user={user}>
              <Home />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Admin"
          element={
            <ProtectedRoute user={user}>
              <Admin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Reports"
          element={
            <ProtectedRoute user={user}>
              <Reports />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Support"
          element={
            <ProtectedRoute user={user}>
              <Support />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Graphs"
          element={
            <ProtectedRoute user={user}>
              <Graphs />
            </ProtectedRoute>
          }
        />

        <Route
          path="/BioflyDeviceDetail"
          element={
            <ProtectedRoute user={user}>
              <BioflyDeviceDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/BioflyDeviceCharts"
          element={
            <ProtectedRoute user={user}>
              <BioflyDeviceCharts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/BioflyDataInTables"
          element={
            <ProtectedRoute user={user}>
              <BioflyDataInTables />
            </ProtectedRoute>
          }
        />
        <Route
          path="/BioflyUserDetails"
          element={
            <ProtectedRoute user={user}>
              <BioflyUserDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path="/ShmDeviceCharts"
          element={
            <ProtectedRoute user={user}>
              <ShmDeviceCharts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ShmDeviceDetail"
          element={
            <ProtectedRoute user={user}>
              <ShmDeviceDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ShmUserDetails"
          element={
            <ProtectedRoute user={user}>
              <ShmUserDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path="/EzytrackDeviceDetail"
          element={
            <ProtectedRoute user={user}>
              <EzytrackDeviceDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EzytrackUserDetails"
          element={
            <ProtectedRoute user={user}>
              <EzytrackUserDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path="/EzymoteDeviceDetail"
          element={
            <ProtectedRoute user={user}>
              <EzymoteDeviceDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EzymoteUserDetails"
          element={
            <ProtectedRoute user={user}>
              <EzymoteUserDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path="/ContactUs"
          element={
            <ProtectedRoute user={user}>
              <ContactUs />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Notification"
          element={
            <ProtectedRoute user={user}>
              <Notification />
            </ProtectedRoute>
          }
        />

        <Route
          path="/login"
          element={!user ? <LoginPage /> : <Navigate to="/" />}
        />
        <Route
          path="/signup"
          element={!user ? <Signup /> : <Navigate to="/" />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
