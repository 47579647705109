import { SensorDatasContext } from '../context/SensorDataContext'
import { useContext } from 'react'

export const useSensorDatasContext = () => {
  const context = useContext(SensorDatasContext)

  if (!context) {
    throw Error('useSensorDatasContext must be used inside an SensorDatasContextProvider')
  }

  return context
}

