import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { useAuthContext } from "../hooks/useAuthContext.js";
import Sw4r from "../components/Sw4r.js";

const EzymoteDeviceDetail = () => {
  const { user } = useAuthContext();
  const location = useLocation();
  const state = location.state || {}; // Default to an empty object if state is undefined
  const { id } = state;

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  // Error handling for missing device ID details
  if (!id || !id.deviceIDDetails) {
    return (
      <div>
        <p>
          Error: Device ID not found. Please ensure you accessed this page
          correctly.
        </p>
        <button onClick={goBack}>Go Back</button>
      </div>
    );
  }

  return (
    <div className="mainPage">
      <Sw4r deviceID={id.deviceIDDetails} />
    </div>
  );
};

export default EzymoteDeviceDetail;
