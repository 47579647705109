import React, { useState, useEffect, useRef } from "react";
import { Line, PolarArea } from "react-chartjs-2";
import { useAuthContext } from "../hooks/useAuthContext";

const DynamicChart = (props) => {
  // console.log("DynamicChart Rendered");

  const { user } = useAuthContext();

  const dataFetchedRef = useRef(false);
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    // console.log("DynamicChart Only once execution here only...");

    // console.log("items1: ", props.items);
  }, [props.items]);

  const divStyles1 = {
    // backgroundColor: "teal",
    // padding: 10,
    // marginTop: 60,
    // marginLeft: 100,
    height: "150px",
    width: "100%",
  };

  return (
    // <div style={divStyles1}>
    <div>
      <h5 className="section-title">{props.items.datasets[0].label} Chart</h5>
      <Line data={props.items} />
    </div>
  );
};
export default DynamicChart;
