// import { useProSidebar } from "react-pro-sidebar";

// import * as React from 'react';
import React, { useMemo, useState, useEffect, useRef } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
// import { useSensorDatasContext } from "../hooks/useSensorDatasContext"

// import React, { useMemo } from 'react';
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";

var jsonDatas;

//nested data is ok, see accessorKeys in ColumnDef below

const data = [
  {
    name: {
      firstName: "John",
      lastName: "Doe",
    },
    address: "261 Erdman Ford",
    city: "East Daphne",
    state: "Kentucky",
  },
  {
    name: {
      firstName: "Jane",
      lastName: "Doe",
    },
    address: "769 Dominic Grove",
    city: "Columbus",
    state: "Ohio",
  },
  // {
  //   name: {
  //     firstName: 'Joe',
  //     lastName: 'Doe',
  //   },
  //   address: '566 Brakus Inlet',
  //   city: 'South Linda',
  //   state: 'West Virginia',
  // },
  // {
  //   name: {
  //     firstName: 'Kevin',
  //     lastName: 'Vandy',
  //   },
  //   address: '722 Emie Stream',
  //   city: 'Lincoln',
  //   state: 'Nebraska',
  // },
  // {
  //   name: {
  //     firstName: 'Joshua',
  //     lastName: 'Rolluffs',
  //   },
  //   address: '32188 Larkin Turnpike',
  //   city: 'Charleston',
  //   state: 'South Carolina',
  // },
  // {
  //   name: {
  //     firstName: 'Joshua',
  //     lastName: 'Rolluffs',
  //   },
  //   address: '32188 Larkin Turnpike',
  //   city: 'Charleston',
  //   state: 'South Carolina',
  // },
  // {
  //   name: {
  //     firstName: 'Joshua',
  //     lastName: 'Rolluffs',
  //   },
  //   address: '32188 Larkin Turnpike',
  //   city: 'Charleston',
  //   state: 'South Carolina',
  // },
];

const blankdata = [
  {
    _id: "649ffc2995a37789d87f1577",
    deviceID: "869523050076489",
    deviceType: "biofly",
    csq: "21",
    batVoltage: "11.7",
    temperature: "25",
    humidity: "70",
    co2: "0.00",
    lux: "0.00",
    temperatureT: "0",
    humidityT: "0",
    co2T: "0",
    temperatureControlState: "enable",
    humidityControlState: "enable",
    co2ControlState: "enable",
    airConditionerState: "off",
    humidifierState: "on",
    exhaustFanState: "off",
    warning: "0",
    error: "0",
    timestamp: "1688206362",
    createdAt: "2023-07-01T10:12:57.569Z",
    updatedAt: "2023-07-01T10:12:57.569Z",
    __v: 0,
  },

  // {
  //   name: {
  //     firstName: 'Joe',
  //     lastName: 'Doe',
  //   },
  //   address: '566 Brakus Inlet',
  //   city: 'South Linda',
  //   state: 'West Virginia',
  // },
  // {
  //   name: {
  //     firstName: 'Kevin',
  //     lastName: 'Vandy',
  //   },
  //   address: '722 Emie Stream',
  //   city: 'Lincoln',
  //   state: 'Nebraska',
  // },
  // {
  //   name: {
  //     firstName: 'Joshua',
  //     lastName: 'Rolluffs',
  //   },
  //   address: '32188 Larkin Turnpike',
  //   city: 'Charleston',
  //   state: 'South Carolina',
  // },
  // {
  //   name: {
  //     firstName: 'Joshua',
  //     lastName: 'Rolluffs',
  //   },
  //   address: '32188 Larkin Turnpike',
  //   city: 'Charleston',
  //   state: 'South Carolina',
  // },
  // {
  //   name: {
  //     firstName: 'Joshua',
  //     lastName: 'Rolluffs',
  //   },
  //   address: '32188 Larkin Turnpike',
  //   city: 'Charleston',
  //   state: 'South Carolina',
  // },
];

var sensorDataDetails;
var glabels = [];
var gdatas = [];

const Reports = () => {
  let [data1, setData1] = useState(blankdata);

  const { user } = useAuthContext();
  // const { dispatch2 } = useSensorDatasContext()
  // const { sensorDatas3, dispatch3 } = useSensorDatasContext()

  // const fetchSensorDataDetails = async (deviceID) => {
  //   const apis = '/api/sensorDatas/test/' + "TLM1003" + "/" + "1000" ;
  //   const response = await fetch(apis, {
  //     headers: { 'Authorization': `Bearer ${user.token}` },
  //   })
  //   const json = await response.json()
  //   // if (response.ok) {
  //   //   //  sensorDataDetails = json;

  //     // console.log("json:")
  //     // console.log(json)
  //   // }
  //   // const newPosts = await response.json();
  //   setData1(json);

  //   // console.log("data1: ")
  //   // console.log(data1)

  // }

  // useEffect(() => {
  // if (user) {
  // fetchSensorDataDetails("TLM1003")
  // console.log("graph data:");
  // console.log( glabels[0], gdatas[0] );
  // console.log("as 2: ")
  // console.log(as)
  //   const fetchSensorDataDetails = async (deviceID) => {
  //     const apis = '/api/sensorDatas/test/' + deviceID + "/" + "2" ;
  //     const response = await fetch(apis, {
  //       headers: { 'Authorization': `Bearer ${user.token}` },
  //     });
  //     const json = await response.json()
  //     if (response.ok) {
  //       console.log("json: ")
  //       console.log(json)
  //       setData1(json);
  //       console.log("data1: ")
  //       console.log(data1)
  //     }
  //   };
  // }

  // setData1(125);

  // setCount(count + 10);

  //     console.log("data1: ")
  // console.log(data1)
  // }, [user]);

  // useEffect(() => {
  //   let interval = setInterval(() => {

  //     // socket.on("login123", (g_tankLevel, g_tankVolume) => {
  //     //   // xsr = g_tankLevel;

  //     //   // console.log("g_tankLevel: ");
  //     //   // console.log(g_tankLevel);

  //     //   // console.log("g_tankVolume: ");
  //     //   // console.log(g_tankVolume);

  //     //   // io.emit("login123", "test123");
  //     // }

  //     //   // socket.on("login123", function (msg) {
  //     //   //   console.log("msg: ");
  //     //   //   console.log(msg);

  //     //   // }

  //     //   // socket.on("login123", function (msg) {
  //     //   //   console.log("msg: ");
  //     //   // console.log(msg);
  //     //   // }

  //     // );

  //     // if(xsr <=20)
  //     // {
  //     //   circleFillColor = "red";
  //     // }
  //     // else if((xsr > 20) && (xsr <70))
  //     // {
  //     //   circleFillColor = "orange";
  //     // }
  //     // else if(xsr >=70)
  //     // {
  //     //   circleFillColor = "green";
  //     // }

  //     // socket.emit("login", text);

  //     /************ */

  //     // const fetchSensorDatas1 = async () => {
  //     //   const response = await fetch('/api/sensorDatas/TLM1001', {
  //     //     headers: { 'Authorization': `Bearer ${user.token}` },
  //     //   })
  //     //   const json = await response.json()

  //     //   // console.log(json)

  //     //         // console.log("Initial fetch of sensor data")

  //     //   if (response.ok) {
  //     //     sensord1 = json;
  //     //   }
  //     // }

  //     if (user) {
  //       // fetchSensorDatass("sarojflame6@gmail.com")

  //       // fetchSensorData("TLM1003")
  //       // fetchSensorDatas1()
  //       console.log("data1: ")
  //     console.log(data1)

  //     }

  //   }, 20000)

  //   return () => {
  //     clearInterval(interval);
  //   }
  // }

  // )

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "name.firstName", //access nested data with dot notation
        header: "First Name",
      },
      {
        accessorKey: "name.lastName",
        header: "Last Name",
      },
      {
        accessorKey: "address", //normal accessorKey
        header: "Address",
      },
      {
        accessorKey: "city",
        header: "City",
      },
      {
        accessorKey: "state",
        header: "State",
      },
    ],
    []
  );

  const columns1 = useMemo(
    () => [
      {
        accessorKey: "date", //access nested data with dot notation
        header: "DATE",
      },
      {
        accessorKey: "time",
        header: "TIME",
      },
      {
        accessorKey: "deviceID",
        header: "DEVICE ID",
      },
      {
        accessorKey: "tankLevel", //normal accessorKey
        header: "TANK LEVEL",
      },
      // {
      //   accessorKey: 'state',
      //   header: 'State',
      // },
    ],
    []
  );

  // const { toggleSidebar, collapseSidebar, broken, collapsed } = useProSidebar();
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    // console.log('Only once execution here only...');

    // toggleSidebar();
    // fetchSensorDataDetails("TLM1003");
  }, []);

  return (
    <>
      <div className="parent">
        <h5>
          <p>Hello {user.userName}</p>
          {/* <main> */}
          {/* <h1><strong>AMANORA ADRENO TOWER OH & UG Water Tank Level Monitoring System</strong></h1> */}
          {/* <h1 style={({ color: "black", marginLeft: "5rem" }, { flexGrow: 1 })}> */}
          <center>You have no notification.</center>
        </h5>
        {/* </h1> */}
        {/* <MaterialReactTable columns={columns} data={data} />; */}
      </div>
    </>
  );
};

export default Reports;
