import React, { useState, useEffect, useRef } from "react";

import { Temperature } from "react-environment-chart";
import { Humidity } from "react-environment-chart";
import { PM } from "react-environment-chart";
import { Electricity } from "react-environment-chart";
// import { Intensity } from "react-environment-chart";
import Typography from "@mui/material/Typography";
import { GiComputerFan } from "react-icons/gi";

// import { TbAirConditioning, TbBold } from "react-icons/tb";
import { MdOutlineAir } from "react-icons/md";

import { WiHumidity } from "react-icons/wi";

// import { useProSidebar } from "react-pro-sidebar";
// import Switch from "react-switch";
import { useAuthContext } from "../hooks/useAuthContext";
// import { useSensorDatasContext } from "../hooks/useSensorDatasContext";
// import { green } from "@mui/material/colors";

import moment from "moment";

import "./bioflySensorBox.css";

// import DynamicChart from "./DynamicChart";

import io from "socket.io-client";

import { useNavigate } from "react-router-dom";

// import RealTimeLineChart from "./RealTimeLineChart";

const baseUrl = process.env.REACT_APP_API_URL;

const socket = io.connect(baseUrl);

const BioflySensorBox = (props) => {
  const deviceID = props.deviceID;

  const [temperatureT, settemperatureT] = useState(62);
  const [humidityT, sethumidityT] = useState(72);
  const [luxT, setluxT] = useState(82);
  const [co2T, setco2T] = useState(92);

  const [co2ControlState, setCo2ControlState] = useState("disable");

  const [co2Control, setco2Control] = useState(false);
  const [luxControl, setluxControl] = useState(false);
  const [humidityControl, sethumidityControl] = useState(false);
  const [temperatureControl, settemperatureControl] = useState(false);

  const dataFetchedRef = useRef(false);

  const [airConditionerState, setairConditionerState] = useState("OFF");
  const [humidifierState, sethumidifierState] = useState("OFF");
  const [exhaustFanState, setExhaustFanState] = useState("OFF");

  const [temperatureControlState, setTemperatureControlState] =
    useState("disable");
  const [humidityControlState, setHumidityControlState] = useState("disable");

  const [temperatureValue, setTemperatureValue] = useState(25);
  const [temperatureColor, setTemperatureColor] = useState("neutral");

  const [airConditionerButtonIconcolor, setAirConditionerButtonIconcolor] =
    useState("green");
  const [humidifierButtonIconcolor, setHumidifierButtonIconcolor] =
    useState("green");
  const [exhaustFanButtoncolor, setExhaustFanButtonIconcolor] =
    useState("green");

  const [exhaustFan1Buttoncolor, setExhaustFan1ButtonIconcolor] =
    useState("green");
  const [exhaustFan2Buttoncolor, setExhaustFan2ButtonIconcolor] =
    useState("green");

  const [airConditionerButton, setAirConditionerButton] = useState(false);
  const [exhaustFanButton, setExhaustFanButton] = useState(false);
  const [humidifierButton, setHumidifierButton] = useState(false);

  const [exhaustFan1Button, setExhaustFan1Button] = useState(false);
  const [exhaustFan2Button, setExhaustFan2Button] = useState(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    // console.log('Only once execution here only...');
    fetchSensorDataDetail(props.deviceID);
  }, []);

  useEffect(() => {
    socket.on(props.deviceID, (json1) => {
      // console.log("receive_message: ", json1);

      setsensordata(json1);

      settemperatureT(parseInt(json1.temperatureT));
      sethumidityT(parseInt(json1.humidityT));
      setco2T(parseInt(json1.co2T));

      if (json1.temperatureControlState === "enable") {
        settemperatureControl(true);
        // console.log("Auto mode temperatureControl enable");
        setTemperatureControlState("enable");
      } else {
        settemperatureControl(false);
        // console.log("Auto mode temperatureControl disable");
        setTemperatureControlState("disable");
      }

      if (json1.airConditionerState === "on") {
        setExhaustFanButton(true);
        setExhaustFanButtonIconcolor("red");
        setairConditionerState("ON");
        // console.log("airConditionerStateon");
      } else {
        setExhaustFanButton(false);
        setExhaustFanButtonIconcolor("green");
        setairConditionerState("OFF");
        // console.log("airConditionerState off");
      }

      if (json1.humidityControlState === "enable") {
        sethumidityControl(true);
        // console.log("Auto mode humidityControl enable");
        setHumidityControlState("enable");
      } else {
        sethumidityControl(false);
        // console.log("Auto mode humidityControl disable");
        setHumidityControlState("disable");
      }

      if (json1.humidifierState === "on") {
        setHumidifierButton(true);
        setHumidifierButtonIconcolor("red");
        sethumidifierState("ON");
        // console.log("humidifierState on");
      } else {
        setHumidifierButton(false);
        setHumidifierButtonIconcolor("green");
        sethumidifierState("OFF");
        // console.log("humidifierState off");
      }

      if (json1.co2ControlState === "enable") {
        setco2Control(true);
        // console.log("Auto mode co2Control enable");
        setCo2ControlState("enable");
      } else {
        setco2Control(false);
        // console.log("Auto mode co2Control disable");
        setCo2ControlState("disable");
      }

      if (json1.exhaustFanState === "on") {
        setExhaustFanButton(true);
        setExhaustFanButtonIconcolor("red");
        setExhaustFanState("ON");
        // console.log("exhaustFanState on");
      } else {
        setExhaustFanButton(false);
        setExhaustFanButtonIconcolor("green");
        setExhaustFanState("OFF");
        // console.log("exhaustFanState off");
      }
    });
  }, [socket]);

  // const co2ControlButtonevent = (nextChecked) => {
  //   setco2Control(nextChecked);

  //   console.log("co2Control: ");
  //   console.log(co2Control);

  //   if (!co2Control) {
  //     // setHumidifierButtonIconcolor("red");////
  //     console.log("co2Control on");
  //     setCo2ControlState("enable");
  //   } else {
  //     // setHumidifierButtonIconcolor("green");
  //     console.log("co2Control off");
  //     setCo2ControlState("disable");
  //   }
  // };

  const co2ControlButtonevent = () => {
    if (!co2Control) {
      setco2Control(true);
      // console.log("Auto mode co2Control enable");
      setCo2ControlState("enable");
    } else {
      setco2Control(false);
      // console.log("Auto mode co2Control disable");
      setCo2ControlState("disable");
    }
  };

  // const temperatureControlButtonevent = (nextChecked) => {
  //   settemperatureControl(nextChecked);

  //   console.log("temperatureControl: ");
  //   console.log(temperatureControl);

  //   if (!temperatureControl) {
  //     // setHumidifierButtonIconcolor("red");////
  //     console.log("temperatureControl on");
  //     setTemperatureControlState("enable");
  //   } else {
  //     // setHumidifierButtonIconcolor("green");
  //     console.log("temperatureControl off");
  //     setTemperatureControlState("disable");
  //   }
  // };
  const temperatureControlButtonevent = () => {
    // console.log("temperatureControl: ");
    // console.log(temperatureControl);

    if (!temperatureControl) {
      settemperatureControl(true);
      // console.log("Auto mode temperatureControl enable");
      setTemperatureControlState("enable");
    } else {
      settemperatureControl(false);
      // console.log("Auto mode temperatureControl disable");
      setTemperatureControlState("disable");
    }
  };

  // const humidityControlButtonevent = (nextChecked) => {
  //   sethumidityControl(nextChecked);

  //   console.log("humidityControl: ");
  //   console.log(humidityControl);

  //   if (!humidityControl) {
  //     // setHumidifierButtonIconcolor("red");////
  //     console.log("humidityControl on");
  //     setHumidityControlState("enable");
  //   } else {
  //     // setHumidifierButtonIconcolor("green");
  //     console.log("humidityControl off");
  //     setHumidityControlState("disable");
  //   }
  // };

  const humidityControlButtonevent = () => {
    // console.log("humidityControl: ");
    // console.log(humidityControl);

    if (!humidityControl) {
      sethumidityControl(true);
      // console.log("Auto mode humidityControl enable");
      setHumidityControlState("enable");
    } else {
      sethumidityControl(false);
      // console.log("Auto mode humidityControl disable");
      setHumidityControlState("disable");
    }
  };

  let [sensordata, setsensordata] = useState(
    '{"deviceID": "VBD10001","deviceType": "bioflyCM","temperature": "31","humidity": "61","lux": "1800","co2": "140","timestamp": "1679764210"}'
  );

  const { user } = useAuthContext();

  const fetchSensorDataDetail = async (deviceID) => {
    const apis = `${baseUrl}/api/sensorDatas/biofly/` + deviceID + "/" + "1";

    const response = await fetch(apis, {
      headers: { Authorization: `Bearer ${user.token}` },
    });
    const json = await response.json();
    if (response.ok) {
      const json1 = json[0];
      // console.log("json1:");
      // console.log(json1);
      setsensordata(json1);

      settemperatureT(parseInt(json1.temperatureT));
      sethumidityT(parseInt(json1.humidityT));
      setco2T(parseInt(json1.co2T));

      if (json1.temperatureControlState === "enable") {
        settemperatureControl(true);
        // console.log("Auto mode temperatureControl enable");
        setTemperatureControlState("enable");
      } else {
        settemperatureControl(false);
        // console.log("Auto mode temperatureControl disable");
        setTemperatureControlState("disable");
      }

      if (json1.airConditionerState === "on") {
        setExhaustFanButton(true);
        setExhaustFanButtonIconcolor("red");
        setairConditionerState("ON");
        // console.log("airConditionerState on");
      } else {
        setExhaustFanButton(false);
        setExhaustFanButtonIconcolor("green");
        setairConditionerState("OFF");
        // console.log("airConditionerState off");
      }

      if (json1.humidityControlState === "enable") {
        sethumidityControl(true);
        // console.log("Auto mode humidityControl enable");
        setHumidityControlState("enable");
      } else {
        sethumidityControl(false);
        // console.log("Auto mode humidityControl disable");
        setHumidityControlState("disable");
      }

      if (json1.humidifierState === "on") {
        setHumidifierButton(true);
        setHumidifierButtonIconcolor("red");
        sethumidifierState("ON");
        // console.log("humidifierState on");
      } else {
        setHumidifierButton(false);
        setHumidifierButtonIconcolor("green");
        sethumidifierState("OFF");
        // console.log("humidifierState off");
      }

      if (json1.co2ControlState === "enable") {
        setco2Control(true);
        // console.log("Auto mode co2Control enable");
        setCo2ControlState("enable");
      } else {
        setco2Control(false);
        // console.log("Auto mode co2Control disable");
        setCo2ControlState("disable");
      }

      if (json1.exhaustFanState === "on") {
        setExhaustFanButton(true);
        setExhaustFanButtonIconcolor("red");
        setExhaustFanState("ON");
        // console.log("exhaustFanState on");
      } else {
        setExhaustFanButton(false);
        setExhaustFanButtonIconcolor("green");
        setExhaustFanState("OFF");
        // console.log("exhaustFanState off");
      }
    }
    return json;
  };

  // useEffect(() => {
  //   let interval = setInterval(() => {
  //     // const res = await fetch(`https://jsonplaceholder.typicode.com/posts`);
  //     const json = fetchSensorDataDetail(deviceID);

  //   }, 30000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  // const { toggleSidebar, collapseSidebar, broken, collapsed } = useProSidebar();

  // const ExhaustFanButtonevent = (nextChecked) => {
  //   setExhaustFanButton(nextChecked);

  //   console.log("exhaustFanButton: ");
  //   console.log(exhaustFanButton);

  //   if (!exhaustFanButton) {
  //     setExhaustFanButtonIconcolor("red");
  //     console.log("Exhaust Fan on");
  //   } else {
  //     setExhaustFanButtonIconcolor("green");
  //     console.log("Exhaust Fan off");
  //   }
  //   //   sendToDevice()
  // };

  const ExhaustFanButtonevent = () => {
    // setExhaustFanButton();

    // console.log("exhaustFanButton: ");
    // console.log(exhaustFanButton);

    if (!exhaustFanButton) {
      setExhaustFanButton(true);
      setExhaustFanButtonIconcolor("red");
      setExhaustFanState("ON");
      // console.log("Exhaust Fan on");

      const ctimestamp = Date.now().toString();
      saveCo2(
        sensordata.deviceID,
        sensordata.deviceType,
        co2T.toString(),
        co2ControlState.toString(),
        "ON".toString(),
        ctimestamp
      );
    } else {
      setExhaustFanButton(false);
      setExhaustFanButtonIconcolor("green");
      setExhaustFanState("OFF");
      // console.log("Exhaust Fan off");

      const ctimestamp = Date.now().toString();
      saveCo2(
        sensordata.deviceID,
        sensordata.deviceType,
        co2T.toString(),
        co2ControlState.toString(),
        "OFF".toString(),
        ctimestamp
      );
    }
    // saveco2T();
  };

  const ExhaustFan1Buttonevent = (nextChecked) => {
    setExhaustFan1Button(nextChecked);

    // console.log("exhaustFan1Button: ");
    // console.log(exhaustFan1Button);

    if (!exhaustFan1Button) {
      setExhaustFan1ButtonIconcolor("red");
      // console.log("Exhaust Fan1 on");
    } else {
      setExhaustFan1ButtonIconcolor("green");
      // console.log("Exhaust Fan1 off");
    }
    //   sendToDevice()
  };

  const ExhaustFan2Buttonevent = (nextChecked) => {
    setExhaustFan2Button(nextChecked);

    // console.log("exhaustFan2Button: ");
    // console.log(exhaustFan2Button);

    if (!exhaustFan2Button) {
      setExhaustFan2ButtonIconcolor("red");
      // console.log("Exhaust Fan2 on");
    } else {
      setExhaustFan2ButtonIconcolor("green");
      // console.log("Exhaust Fan2 off");
    }
    //   sendToDevice()
  };

  // const AirConditionerButtonevent = (nextChecked) => {
  //   setAirConditionerButton(nextChecked);

  //   console.log("airConditionerButton: ");
  //   console.log(airConditionerButton);

  //   if (!airConditionerButton) {
  //     setAirConditionerButtonIconcolor("red");
  //     console.log("AirConditioner on");
  //   } else {
  //     setAirConditionerButtonIconcolor("green");
  //     console.log("AirConditioner off");
  //   }
  // };

  const AirConditionerButtonevent = () => {
    // console.log("airConditionerButton: ");
    // console.log(airConditionerButton);

    if (!airConditionerButton) {
      setAirConditionerButton(true);
      setAirConditionerButtonIconcolor("red");
      setairConditionerState("ON");
      // console.log("AirConditioner on");

      const ctimestamp = Date.now().toString();
      saveTemperatureT(
        sensordata.deviceID,
        sensordata.deviceType,
        temperatureT.toString(),
        temperatureControlState.toString(),
        "ON",
        ctimestamp
      );
    } else {
      setAirConditionerButton(false);
      setAirConditionerButtonIconcolor("green");
      setairConditionerState("OFF");
      // console.log("AirConditioner off");

      const ctimestamp = Date.now().toString();
      saveTemperatureT(
        sensordata.deviceID,
        sensordata.deviceType,
        temperatureT.toString(),
        temperatureControlState.toString(),
        "OFF",
        ctimestamp
      );
    }
    // savetemperatureT();
  };

  // const [humidifierButton, setHumidifierButton] = useState(false);
  // const [humidifierState, sethumidifierState] = useState("OFF");

  // const HumidifierButtonevent = () => {
  //   console.log("humidifierButton: ");
  //   console.log(humidifierButton);

  //   if (!humidifierButton) {
  //     setHumidifierButton(true);
  //     setHumidifierButtonIconcolor("red");
  //     sethumidifierState("ON");
  //     console.log("Humidifier on");
  //   } else {
  //     setHumidifierButton(false);
  //     setHumidifierButtonIconcolor("green");
  //     sethumidifierState("OFF");
  //     console.log("Humidifier off");
  //   }
  // };

  const HumidifierButtonevent = () => {
    // console.log("humidifierButton: ");
    // console.log(humidifierButton);

    if (!humidifierButton) {
      setHumidifierButton(true);
      setHumidifierButtonIconcolor("red");
      sethumidifierState("ON");
      // console.log("Humidifier on");

      const ctimestamp = Date.now().toString();
      saveHumidityT(
        sensordata.deviceID,
        sensordata.deviceType,
        humidityT.toString(),
        humidityControlState.toString(),
        "ON".toString(),
        ctimestamp
      );
    } else {
      setHumidifierButton(false);
      setHumidifierButtonIconcolor("green");
      sethumidifierState("OFF");
      // console.log("Humidifier off");

      const ctimestamp = Date.now().toString();
      saveHumidityT(
        sensordata.deviceID,
        sensordata.deviceType,
        humidityT.toString(),
        humidityControlState.toString(),
        "OFF".toString(),
        ctimestamp
      );
    }
    // savehumidityT();
  };
  // onClick={HumidifierButtonevent}
  // >
  //   <p>{humidifierState}</p>
  // const HumidifierButtonevent = (nextChecked) => {
  //   setHumidifierButton(nextChecked);

  //   console.log("humidifierButton: ");
  //   console.log(humidifierButton);

  //   if (!humidifierButton) {
  //     setHumidifierButtonIconcolor("red");
  //     console.log("Humidifier on");
  //   } else {
  //     setHumidifierButtonIconcolor("green");
  //     console.log("Humidifier off");
  //   }
  // };

  const fetchSensorData = async (
    deviceID,
    deviceType,
    temperatureT,
    humidityT,
    luxT,
    co2T,
    humidityControlState,
    ctimestamp
  ) => {
    const apis = `${baseUrl}/api/sensorDatas/bioflymqtt`;

    const response = await fetch(apis, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // headers: { 'Authorization': `Bearer ${user.token}` },
      body: JSON.stringify({
        deviceID,
        deviceType,
        temperatureT,
        humidityT,
        luxT,
        co2T,
        humidityControlState,
        ctimestamp,
      }),
    });

    // const json = await response.json();
    // console.log("jso:", json);

    // if (response.ok) {
    // console.log("ok");
    // } else {
    // console.log("err");
    // }
  };

  const saveTemperatureT = async (
    deviceID,
    deviceType,
    temperatureT,
    temperatureControlState,
    airConditionerState,
    ctimestamp
  ) => {
    const apis = `${baseUrl}/api/sensorDatas/bioflymqtt`;

    const response = await fetch(apis, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // headers: { 'Authorization': `Bearer ${user.token}` },
      body: JSON.stringify({
        deviceID,
        deviceType,
        temperatureT,
        temperatureControlState,
        airConditionerState,
        ctimestamp,
      }),
    });

    const json = await response.json();
    // console.log("jso:", json);

    if (response.ok) {
      // console.log("ok");
      // alert("Save success.");
    } else {
      // console.log("err");
      // alert("Save error!");
    }
  };

  const saveHumidityT = async (
    deviceID,
    deviceType,
    humidityT,
    humidityControlState,
    humidifierState,
    ctimestamp
  ) => {
    const apis = `${baseUrl}/api/sensorDatas/bioflymqtt`;

    const response = await fetch(apis, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // headers: { 'Authorization': `Bearer ${user.token}` },
      body: JSON.stringify({
        deviceID,
        deviceType,
        humidityT,
        humidityControlState,
        humidifierState,
        ctimestamp,
      }),
    });

    const json = await response.json();
    // console.log("jso:", json);

    if (response.ok) {
      // console.log("ok");
      // alert("Save success.");
    } else {
      // alert("Save error!");
      // console.log("err");
    }
  };

  const saveCo2 = async (
    deviceID,
    deviceType,
    co2T,
    co2ControlState,
    exhaustFanState,
    ctimestamp
  ) => {
    const apis = `${baseUrl}/api/sensorDatas/bioflymqtt`;

    const response = await fetch(apis, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // headers: { 'Authorization': `Bearer ${user.token}` },
      body: JSON.stringify({
        deviceID,
        deviceType,
        co2T,
        co2ControlState,
        exhaustFanState,
        ctimestamp,
      }),
    });

    const json = await response.json();
    // console.log("jso:", json);

    if (response.ok) {
      // console.log("ok");
      // alert("Save success.");
    } else {
      // console.log("err");
      // alert("Save error!");
    }
  };

  const increasetemperatureT = () => {
    const newtemperatureT = temperatureT + 1;
    settemperatureT(newtemperatureT);
    // if (newTemperature >= 15) {
    // 	setTemperatureColor('hot');
    // }
  };

  const decreasetemperatureT = () => {
    const newtemperatureT = temperatureT - 1;
    settemperatureT(newtemperatureT);
    // if (newTemperature < 15) {
    // 	setTemperatureColor('cold');
    // }
  };

  const savetemperatureT = () => {
    const ctimestamp = Date.now().toString();
    saveTemperatureT(
      sensordata.deviceID,
      sensordata.deviceType,
      temperatureT.toString(),
      temperatureControlState.toString(),
      airConditionerState.toString(),
      ctimestamp
    );
  };

  const increasehumidityT = () => {
    const newhumidityT = humidityT + 1;
    sethumidityT(newhumidityT);
    // if (newTemperature >= 15) {
    // 	setTemperatureColor('hot');
    // }
  };

  const decreasehumidityT = () => {
    const newhumidityT = humidityT - 1;
    sethumidityT(newhumidityT);
    // if (newTemperature < 15) {
    // 	setTemperatureColor('cold');
    // }
  };

  const savehumidityT = () => {
    const ctimestamp = Date.now().toString();
    saveHumidityT(
      sensordata.deviceID,
      sensordata.deviceType,
      humidityT.toString(),
      humidityControlState.toString(),
      humidifierState.toString(),
      ctimestamp
    );
  };

  const increaseco2T = () => {
    const newco2T = co2T + 1;
    setco2T(newco2T);
    // if (newTemperature >= 15) {
    // 	setTemperatureColor('hot');
    // }
  };

  const decreaseco2T = () => {
    const newco2T = co2T - 1;
    setco2T(newco2T);
    // if (newTemperature < 15) {
    // 	setTemperatureColor('cold');
    // }
  };

  const saveco2T = () => {
    const ctimestamp = Date.now().toString();
    saveCo2(
      sensordata.deviceID,
      sensordata.deviceType,
      co2T.toString(),
      co2ControlState.toString(),
      exhaustFanState.toString(),
      ctimestamp
    );
  };

  const date = moment.unix(sensordata.timestamp).format("DD/MM/YYYY");
  // console.log(date);

  const time = moment.unix(sensordata.timestamp).format("hh:mm:ss");
  // console.log(time);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <section className="border text-center mb-4">
      <h4 className="mb-3">Climate Monitor and Control Panel</h4>

      <button type="button" class="btn btn-info" onClick={goBack}>
        Back
      </button>

      <p>
        Device ID: <b>{sensordata.deviceID}</b>
      </p>
      <p>
        Last updated on: Time: <b>{time}</b> Date: <b>{date}</b>
      </p>

      <div>
        {/* <div>
          <h1>Real-time Accelerometer Data</h1>
          <RealTimeLineChart />
        </div> */}

        <div className="child">
          {/* <div style={appContainer}> */}
          <div className="cardSensor-bigcontainer">
            <div
              style={{
                paddingLeft: "80px",
                verticalAlign: "center",
                paddingTop: "30px",
              }}
            >
              <Temperature
                value={sensordata.temperature}
                height={210}
                maxValue={100}
              />
            </div>
            <Typography
              align="center"
              variant="subtitle1"
              fontWeight={1000}
              style={{
                // opacity: collapsed ? 0 : 0.7,
                letterSpacing: "0.5px",
                padding: "10px",
              }}
            >
              {sensordata.temperature} °C
            </Typography>
          </div>

          <Typography
            align="center"
            variant="subtitle1"
            fontWeight={1000}
            style={{
              // opacity: collapsed ? 0 : 0.7,
              letterSpacing: "0.5px",
              padding: "10px",
            }}
          >
            Temperature
          </Typography>
        </div>

        <div className="child">
          {/* <div style={appContainer}> */}
          <div className="cardSensor-bigcontainer">
            <div
              style={{
                paddingLeft: "20px",
                verticalAlign: "center",
                paddingTop: "30px",
              }}
            >
              {/* <div style={{alignItems: 'center', verticalAlign: 'center'}}> */}
              <Humidity value={sensordata.humidity} height={100} />

              {/* <Intensity/> */}
            </div>

            <Typography
              align="center"
              variant="subtitle1"
              fontWeight={1000}
              style={{
                // opacity: collapsed ? 0 : 0.7,
                letterSpacing: "0.5px",
                padding: "10px",
              }}
            >
              {sensordata.humidity}%
            </Typography>
          </div>
          <Typography
            align="center"
            variant="subtitle1"
            fontWeight={1000}
            style={{
              // opacity: collapsed ? 0 : 0.7,
              letterSpacing: "0.5px",
              padding: "10px",
            }}
          >
            Humidity
          </Typography>
        </div>

        <div className="child">
          {/* <div style={appContainer}> */}
          <div className="cardSensor-bigcontainer">
            <div
              style={{
                paddingLeft: "30px",
                verticalAlign: "center",
                paddingTop: "30px",
              }}
            >
              {/* <div style={{alignItems: 'center', verticalAlign: 'center'}}> */}
              <PM value={sensordata.lux} height={200} />
            </div>

            <Typography
              align="center"
              variant="subtitle1"
              fontWeight={1000}
              style={{
                // opacity: collapsed ? 0 : 0.7,
                letterSpacing: "0.5px",
                padding: "10px",
              }}
            >
              {sensordata.lux}
            </Typography>
          </div>
          <Typography
            align="center"
            variant="subtitle1"
            fontWeight={1000}
            style={{
              // opacity: collapsed ? 0 : 0.7,
              letterSpacing: "0.5px",
              padding: "10px",
            }}
          >
            Lux
          </Typography>
        </div>

        <div className="child">
          {/* <div style={appContainer}> */}
          <div className="cardSensor-bigcontainer">
            <div
              style={{
                paddingLeft: "30px",
                verticalAlign: "center",
                paddingTop: "30px",
              }}
            >
              {/* <div style={{alignItems: 'center', verticalAlign: 'center'}}> */}
              <Electricity value={sensordata.co2 / 10} height={200} />
            </div>

            <Typography
              align="center"
              variant="subtitle1"
              fontWeight={1000}
              style={{
                // opacity: collapsed ? 0 : 0.7,
                letterSpacing: "0.5px",
                padding: "10px",
              }}
            >
              {sensordata.co2}
            </Typography>
          </div>
          <Typography
            align="center"
            variant="subtitle1"
            fontWeight={1000}
            style={{
              // opacity: collapsed ? 0 : 0.7,
              letterSpacing: "0.5px",
              padding: "10px",
            }}
          >
            Co2
          </Typography>
        </div>

        <div className="child">
          <div className="cardSensor-bigcontainer">
            <center>
              <MdOutlineAir
                size={80}
                style={{
                  color: airConditionerButtonIconcolor,
                  fontSize: "50px",
                }}
              />
              {/* <Switch
              onChange={temperatureControlButtonevent}
              checked={temperatureControl}
              className="react-switch"
            /> */}
              Auto mode is{" "}
              <button
                href="#"
                className="checkbutton"
                onClick={temperatureControlButtonevent}
              >
                <p>{temperatureControlState}</p>
              </button>
            </center>
            {/* <p> */}
            <center>
              {/* <span>{airConditionerButton ? "ON" : "OFF"}</span> */}
            </center>
            {/* </p> */}
            <center>
              {/* <Switch
              onChange={AirConditionerButtonevent}
              checked={airConditionerButton}
              className="react-switch"
            /> */}
              <button
                href="#"
                className="controlbutton"
                onClick={AirConditionerButtonevent}
              >
                <p>{airConditionerState}</p>
              </button>
            </center>
            <center>Trigger AC on Temperature</center>

            <center>
              <br></br>

              {/* <div className="cardButton-container"> */}
              <div className={`temperature-display ${temperatureColor}`}>
                <b>{temperatureT} °C </b>
              </div>

              <br></br>

              {/* // </div> */}
              <div className="button-container">
                {/* <button onClick={decreasetemperatureT}>-</button>
              <button onClick={savetemperatureT}> SET </button>
              <button onClick={increasetemperatureT}>+</button> */}

                <button
                  href="#"
                  className="controlbutton"
                  onClick={decreasetemperatureT}
                >
                  -
                </button>
                <button
                  href="#"
                  className="controlbutton"
                  onClick={savetemperatureT}
                >
                  {" "}
                  SET{" "}
                </button>
                <button
                  href="#"
                  className="controlbutton"
                  onClick={increasetemperatureT}
                >
                  +
                </button>
              </div>
              {/* </div> */}
            </center>
          </div>
          <Typography
            align="center"
            variant="subtitle1"
            fontWeight={1000}
            style={{
              // opacity: collapsed ? 0 : 0.7,
              letterSpacing: "0.5px",
              padding: "10px",
            }}
          >
            Air Conditioner
          </Typography>
        </div>

        <div className="child">
          <div className="cardSensor-bigcontainer">
            <center>
              <WiHumidity
                size={80}
                style={{ color: humidifierButtonIconcolor, fontSize: "50px" }}
              />
              {/* <Switch
              onChange={humidityControlButtonevent}
              checked={humidityControl}
              className="react-switch"
            /> */}
              Auto mode{" "}
              <button
                href="#"
                className="checkbutton"
                onClick={humidityControlButtonevent}
              >
                <p>{humidityControlState}</p>
              </button>
            </center>
            {/* <p> */}
            <center>
              {/* <span>{humidifierButton ? "ON" : "OFF"}</span> */}
            </center>
            {/* </p> */}
            <center>
              {/* <Switch
              onChange={HumidifierButtonevent}
              checked={humidifierButton}
              className="react-switch"
            /> */}
              <button
                href="#"
                className="controlbutton"
                onClick={HumidifierButtonevent}
              >
                <p>{humidifierState}</p>
              </button>
            </center>
            <center>Trigger Humidifier on Humidity </center>

            <center>
              <br></br>

              {/* <div className="cardButton-container"> */}
              <div className={`temperature-display ${temperatureColor}`}>
                <b>{humidityT} % </b>
              </div>

              <br></br>

              {/* // </div> */}
              <div className="button-container">
                {/* <button onClick={decreasehumidityT}>-</button>
              <button onClick={savehumidityT}> SET </button>
              <button onClick={increasehumidityT}>+</button> */}

                <button
                  href="#"
                  className="controlbutton"
                  onClick={decreasehumidityT}
                >
                  -
                </button>
                <button
                  href="#"
                  className="controlbutton"
                  onClick={savehumidityT}
                >
                  {" "}
                  SET{" "}
                </button>
                <button
                  href="#"
                  className="controlbutton"
                  onClick={increasehumidityT}
                >
                  +
                </button>
              </div>
              {/* </div> */}
            </center>
          </div>
          <Typography
            align="center"
            variant="subtitle1"
            fontWeight={1000}
            style={{
              // opacity: collapsed ? 0 : 0.7,
              letterSpacing: "0.5px",
              padding: "10px",
            }}
          >
            Humidifier
          </Typography>
        </div>

        <div className="child">
          <div className="cardSensor-bigcontainer">
            <center>
              <GiComputerFan
                size={80}
                style={{ color: exhaustFanButtoncolor, fontSize: "50px" }}
              />
              {/* <Switch
              onChange={co2ControlButtonevent}
              checked={co2Control}
              className="react-switch"
            /> */}
              {/* <button onClick={co2ControlButtonevent}>-</button> */}
              Auto mode is{" "}
              <button
                href="#"
                className="checkbutton"
                onClick={co2ControlButtonevent}
              >
                <p>{co2ControlState}</p>
              </button>
            </center>
            <center>
              {/* <span>{exhaustFanButton ? "ON" : "OFF"}</span> */}
            </center>
            <center>
              {/* <Switch
              className="react-switch"
              onChange={ExhaustFanButtonevent}
              checked={exhaustFanButton}
            /> */}
              <button
                href="#"
                className="controlbutton"
                onClick={ExhaustFanButtonevent}
              >
                <p>{exhaustFanState}</p>
              </button>
            </center>

            <center>Trigger Fan on Co2</center>

            <center>
              <br></br>
              <div className={`temperature-display ${temperatureColor}`}>
                <b>{co2T} ppm </b>
              </div>

              <br></br>
              <div className="button-container">
                {/* <button onClick={decreaseco2T}>-</button>
              <button onClick={saveco2T}> SET </button>
              <button onClick={increaseco2T}>+</button> */}

                <button
                  href="#"
                  className="controlbutton"
                  onClick={decreaseco2T}
                >
                  -
                </button>
                <button href="#" className="controlbutton" onClick={saveco2T}>
                  {" "}
                  SET{" "}
                </button>
                <button
                  href="#"
                  className="controlbutton"
                  onClick={increaseco2T}
                >
                  +
                </button>
              </div>
            </center>
          </div>
          <Typography
            align="center"
            variant="subtitle1"
            fontWeight={1000}
            style={{
              // opacity: collapsed ? 0 : 0.7,
              letterSpacing: "0.5px",
              padding: "10px",
            }}
          >
            Exhaust Fan
          </Typography>
        </div>

        {/* <TempSensorCard/> */}
      </div>
    </section>
  );
};

export default BioflySensorBox;
