import React, { useState, useEffect, useRef } from "react";
import { Temperature, PM } from "react-environment-chart";
import Typography from "@mui/material/Typography";
import { useAuthContext } from "../hooks/useAuthContext";
import moment from "moment";
import ProgressBar from "./ProgressBar.js";

const baseUrl = process.env.REACT_APP_API_URL;

const ShmSensorBox = ({ deviceID }) => {
  const { user } = useAuthContext();
  const dataFetchedRef = useRef(false);

  const [sensorData, setSensorData] = useState({
    deviceID: "",
    temperature: "0",
    humidity: "0",
    vibration: "0",
    tiltMeter: "0",
    timestamp: null,
  });

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const fetchSensorData = async () => {
      try {
        const response = await fetch(`/api/sensorDatas/shm/${deviceID}/1`, {
          headers: { Authorization: `Bearer ${user.token}` },
        });

        if (!response.ok) {
          throw new Error(
            "Failed to fetch sensor data: " + response.statusText
          );
        }

        // const contentType = response.headers.get("content-type");
        // if (!contentType || !contentType.includes("application/json")) {
        //   throw new Error("Received non-JSON response");
        // }

        const [data] = await response.json();
        console.log("data", data);
        // setSensorData(data[0]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchSensorData();
  }, [user.token, deviceID]);

  const {
    timestamp,
    deviceID: id,
    temperature,
    vibration,
    tiltMeter,
  } = sensorData;
  const formattedDate = timestamp
    ? moment.unix(timestamp).format("DD/MM/YYYY")
    : "";
  const formattedTime = timestamp
    ? moment.unix(timestamp).format("hh:mm:ss")
    : "";

  return (
    <section className="border text-center mb-4">
      <div>
        <p>
          Device ID: <b>{id}</b>
        </p>
        <p>
          Last updated on: Time: <b>{formattedTime}</b> Date:{" "}
          <b>{formattedDate}</b>
        </p>

        <SensorMetric
          label="Temperature"
          value={temperature}
          Component={Temperature}
        />

        <SensorMetric label="Vibration" value={vibration} Component={PM} />

        <SensorMetric label="TiltMeter" value={tiltMeter} Component={PM} />

        <Accelerometer />
      </div>
    </section>
  );
};

const SensorMetric = ({ label, value, Component }) => (
  <div className="child">
    <div className="cardSensor-bigcontainer">
      <div style={{ paddingLeft: "30px", paddingTop: "30px" }}>
        <Component value={value} height={200} />
      </div>
      <Typography align="center" variant="subtitle1" fontWeight={1000}>
        {value}
      </Typography>
    </div>
    <Typography align="center" variant="subtitle1" fontWeight={1000}>
      {label}
    </Typography>
  </div>
);

const Accelerometer = () => (
  <div className="child">
    <div className="cardSensor-bigcontainer">
      <ProgressBar
        key={1}
        LabelName="X-axis"
        bgcolor={"#6a1b9a"}
        completed={57}
      />
      <ProgressBar
        key={2}
        LabelName="Y-axis"
        bgcolor={"#00695c"}
        completed={33}
      />
      <ProgressBar
        key={3}
        LabelName="Z-axis"
        bgcolor={"#ef6c00"}
        completed={46}
      />
    </div>
    <Typography align="center" variant="subtitle1" fontWeight={1000}>
      Accelerometer
    </Typography>
  </div>
);

export default ShmSensorBox;
