import {
  createSearchParams,
  Link,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import * as React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";

import logo from "../icons/logo.png";

import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";

const pages = ["Dashboard", "Notification", "Contact Us"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

export default function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { user } = useAuthContext();

  const { logout } = useLogout();

  const handleLogout = () => {
    logout();
  };

  const navigate = useNavigate();

  const navigateDashboard = () => {
    navigate("/");
  };

  const navigateGraphs = () => {
    navigate("/Graphs");
  };

  const navigateTables = () => {
    navigate("/Reports");
  };

  const navigateContactUs = () => {
    navigate("/ContactUs");
  };

  const navigateNotification = () => {
    navigate("/Notification");
  };

  return (
    // <AppBar position="static">
    <AppBar>
      {user && (
        <div>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              {/* <Toolbar> */}
              {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
              {/* <img src={logo} width={60} height={60} /> */}
              <Typography
                variant="h6"
                noWrap
                // component="a"
                // href="/"
                sx={{
                  mr: 15,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".0rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                Vincenzo Technology
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {/* {pages.map((page) => (
                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  ))} */}

                  <MenuItem
                    // key={pages[0]}
                    onClick={() => {
                      handleCloseNavMenu();
                      navigateDashboard();
                    }}
                  >
                    <Typography textAlign="center">{pages[0]}</Typography>
                  </MenuItem>

                  <MenuItem
                    // key={pages[1]}
                    onClick={() => {
                      handleCloseNavMenu();
                      navigateNotification();
                    }}
                  >
                    <Typography textAlign="center">{pages[1]}</Typography>
                  </MenuItem>

                  <MenuItem
                    // key={pages[2]}
                    onClick={() => {
                      handleCloseNavMenu();
                      navigateContactUs();
                    }}
                  >
                    <Typography textAlign="center">{pages[2]}</Typography>
                  </MenuItem>
                </Menu>
              </Box>
              {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
              {/* <img src={logo} width={60} height={60} /> */}

              {/* <img src={logo} width={60} height={60} /> */}
              <Typography
                variant="h5"
                noWrap
                component="a"
                href=""
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".0rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                {/* Vincenzo Technology */}
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {/* {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page}
              </Button>
            ))} */}

                {/* <MenuItem key="page1" onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Dashboard</Typography>
                </MenuItem> */}

                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    navigateDashboard();
                  }}
                >
                  <Typography textAlign="center">Dashboard</Typography>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    navigateNotification();
                  }}
                >
                  <Typography textAlign="center">Notification</Typography>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    navigateContactUs();
                  }}
                >
                  <Typography textAlign="center">Contact Us</Typography>
                </MenuItem>

                {/* <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    navigateDashboard();
                  }}
                >
                  <Typography textAlign="center">About Us</Typography>
                </MenuItem> */}

                {/* <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    navigateGraphs();
                  }}
                >
                  <Typography textAlign="center">Charts</Typography>
                </MenuItem> */}

                {/* <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    navigateTables();
                  }}
                >
                  <Typography textAlign="center">Tables</Typography>
                </MenuItem> */}

                {/* <Button
                  key="Dashboard"
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  Dashboard
                </Button> */}

                {/* <Button
                  key="Dashboard"
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  Dashboard
                </Button> */}
              </Box>

              <Typography
                variant="subtitle1"
                component="div"
                sx={{ alignItems: "flex-end" }}
              >
                Welcome, {user.userName}
              </Typography>

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt="Remy Sharp"
                      // src="/static/images/avatar/2.jpg"
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleCloseUserMenu}>Profile</MenuItem>
                  <MenuItem onClick={handleCloseUserMenu}>My account</MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      handleLogout();
                    }}
                  >
                    Logout
                  </MenuItem>

                  {/* {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))} */}
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </div>
      )}
    </AppBar>
  );
}
