import { createContext, useReducer } from 'react'

export const SensorDatasContext = createContext()

export const sensorDatasReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SENSORDATAS': 
      return {
        sensorDatas: action.payload
      }
    case 'CREATE_SENSORDATA':
      return {
        sensorDatas: [action.payload, ...state.sensorDatas]
      }
    case 'DELETE_SENSORDATA':
      return {
        sensorDatas: state.sensorDatas.filter((w) => w._id !== action.payload._id)
      }
    default:
      return state
  }
}

export const SensorDatasContextProvider = ({ children }) => {
  const [state, dispatch2] = useReducer(sensorDatasReducer, {
    sensorDatas: null
  })

  return (
    <SensorDatasContext.Provider value={{...state, dispatch2}}>
      { children }
    </SensorDatasContext.Provider>
  )
}

