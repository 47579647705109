import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const LineChart = () => {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: "responsive-line-chart",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
        },
      },
    ],
  });

  const [chartSeries, setChartSeries] = useState([
    {
      name: "Monthly Data",
      data: [30, 40, 25, 50, 49, 21, 70, 51, 60, 80, 90, 120],
    },
  ]);

  useEffect(() => {
    // Additional setup or data fetching logic can be added here
  }, []);

  return (
    <div>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="line"
        width="100%"
        height={300}
      />
    </div>
  );
};

export default LineChart;
