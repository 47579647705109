import React from "react";
import {
  MdSignalWifiStatusbar1Bar,
  MdSignalWifiStatusbar2Bar,
  MdSignalWifiStatusbar3Bar,
  MdSignalWifiStatusbar4Bar,
  MdSignalWifiOff,
} from "react-icons/md";
import "./WifiStrengthIndicator.css"; // Import the CSS file

const WifiStrengthIndicator = ({ csq }) => {
  let wifiIcon;
  let iconClass = "wifi-icon";

  if (csq === 0) {
    wifiIcon = <MdSignalWifiOff />;
    iconClass += " wifi-off";
  } else if (csq >= 1 && csq <= 8) {
    wifiIcon = <MdSignalWifiStatusbar1Bar />;
    iconClass += " wifi-weak";
  } else if (csq >= 9 && csq <= 16) {
    wifiIcon = <MdSignalWifiStatusbar2Bar />;
    iconClass += " wifi-moderate";
  } else if (csq >= 17 && csq <= 24) {
    wifiIcon = <MdSignalWifiStatusbar3Bar />;
    iconClass += " wifi-strong";
  } else if (csq >= 25 && csq <= 31) {
    wifiIcon = <MdSignalWifiStatusbar4Bar />;
    iconClass += " wifi-excellent";
  }

  return (
    <div className="wifi-container">
      <p>WiFi Strength:</p>
      <div className={iconClass}>{wifiIcon}</div>
    </div>
  );
};

export default WifiStrengthIndicator;
