/*
for showing the vehicle current position

*/

import React from "react";

import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Polyline,
} from "@react-google-maps/api";

import { useAuthContext } from "../hooks/useAuthContext";
import { faBus, faCarSide } from "@fortawesome/free-solid-svg-icons";

// import { FaCarSide } from "react-icons/fa";
// import { FaCarSide } from "react-icons/fa6";

// const google = window.google;

const baseUrl = process.env.REACT_APP_API_URL;

const containerStyle1 = {
  // width: "1200px",
  // height: "600px",

  width: "vw-100",
  height: "100%",
};

const center = { lat: 20.246975, lng: 85.83977333 };

const Maps = () => {
  const { user } = useAuthContext();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);

    map.setZoom(17);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const markers = [
    { lat: 20.246902, lng: 85.83977 },
    { lat: 20.247002, lng: 85.83977 },
    { lat: 20.247102, lng: 85.83807 },
    { lat: 20.247302, lng: 85.83628 },
    // Add more points as needed
  ];

  const center = { lat: 20.246968, lng: 85.83966 }; // Set the initial center of the map
  const zoom = 10; // Set the initial zoom level

  const polylines = [
    {
      path: [
        { lat: 20.246902, lng: 85.83977 },
        { lat: 20.247002, lng: 85.83977 },
        { lat: 20.247102, lng: 85.83807 },
        { lat: 20.247302, lng: 85.83628 },
      ],
      color: "blue", // Specify the color of the Polyline
    },
    // Add more polylines as needed
  ];

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle1}
      // zoom={19}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onTiltChanged={25}
      // tilt={40}
      // tilt={-105}
      // mapTypeId="satellite"
    >
      {/* Child components, such as markers, info windows, etc. */}

      {/* <> */}

      {/* <Marker
        icon={{
          path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
          fillColor: "blue",
          fillOpacity: 0.6,
          strokeWeight: 0,
          rotation: 0,
          scale: 2,
          //   anchor: new google.maps.Point(0, 20),
        }}
        position={center}
        label={user.userName + "'s Car"}
      /> */}

      <Marker
        icon={{
          path: faCarSide.icon[1],
          fillColor: "#0000ff",
          fillOpacity: 1,
          //   anchor: new google.maps.Point(
          //     faBus.icon[0] / 2, // width
          //     faBus.icon[1] // height
          //   ),
          strokeWeight: 1,
          strokeColor: "#ffffff",
          scale: 0.05,
        }}
        position={center}
        // label={user.userName + "'s Car"}
      />

      {/* {markers.map((marker, index) => (
        <Marker
          icon={{
            path: faCarSide.icon[4],
            fillColor: "#0000ff",
            fillOpacity: 1,
            //   anchor: new google.maps.Point(
            //     faBus.icon[0] / 2, // width
            //     faBus.icon[1] // height
            //   ),
            strokeWeight: 1,
            strokeColor: "#ffffff",
            scale: 0.05,
          }}
          key={index}
          position={{ lat: marker.lat, lng: marker.lng }}
          // label={user.userName + "'s Car"}
        />
      ))} */}

      {/* Render your Polylines here */}
      {/* {polylines.map((polyline, index) => (
        <Polyline
          key={index}
          path={polyline.path}
          options={{
            strokeColor: polyline.color,
            strokeOpacity: 1,
            strokeWeight: 3,
          }}
        />
      ))} */}

      {/* </> */}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default Maps;
