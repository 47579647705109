import React, { useState, useRef, useEffect } from "react";

import { useLocation } from "react-router-dom";

import DynamicChart from "../components/DynamicChart";

import { Route, Link, Routes, useNavigate } from "react-router-dom";

import { useAuthContext } from "../hooks/useAuthContext.js";

import moment from "moment";

// for date time range picker only
import { DateRangePicker, Stack } from "rsuite";
import "../components/DateRangePickerstyles.css";
// for date time range picker only end

import LineChart from "../components/LineChart.js";

const baseUrl = process.env.REACT_APP_API_URL;

const BioflyDeviceCharts = () => {
  const { user } = useAuthContext();

  const { state } = useLocation();
  const { id } = state;
  // console.log("id............");
  // console.log(id);

  // console.log("user:");
  // console.log(user);

  //   console.log("deviceID:");
  // console.log(id.number);

  // console.log("deviceID123:");
  // console.log(id.number);

  // console.log(id.state)

  // const location = useLocation();

  // console.log("location:")
  // console.log(location)

  // const [searchparams] = useSearchParams();

  // console.log(searchparams.get("id"));

  // console.log("deviceID:")
  // console.log(deviceID)

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [items22, setItems22] = useState();

  const [items, setItems] = useState();
  const [items2, setItems2] = useState();
  const [items3, setItems3] = useState();
  const [items4, setItems4] = useState();

  var temperatureChartData = {
    // labels: ["January", "February", "March", "April", "May", "June"],
    labels: [],
    datasets: [
      // {
      //   label: "Sales",
      //   data: [21, 35, 75, 51, 41, 47],
      //   fill: false, // for Line chart
      //   backgroundColor: "#FB8833",
      //   borderColor: "#FB8833", // for Line chart
      // },
      {
        label: "Temperature",
        data: [],
        fill: false, // for Line chart
        backgroundColor: "#17A8F5",
        borderColor: "#17A8F5", // for Line chart
      },
    ],
  };

  var humidityChartData = {
    // labels: ["January", "February", "March", "April", "May", "June"],
    labels: [],
    datasets: [
      // {
      //   label: "Sales",
      //   data: [21, 35, 75, 51, 41, 47],
      //   fill: false, // for Line chart
      //   backgroundColor: "#FB8833",
      //   borderColor: "#FB8833", // for Line chart
      // },
      {
        label: "Humidity",
        data: [],
        fill: false, // for Line chart
        backgroundColor: "#17A8F5",
        borderColor: "#17A8F5", // for Line chart
      },
    ],
  };

  var luxChartData = {
    // labels: ["January", "February", "March", "April", "May", "June"],
    labels: [],
    datasets: [
      // {
      //   label: "Sales",
      //   data: [21, 35, 75, 51, 41, 47],
      //   fill: false, // for Line chart
      //   backgroundColor: "#FB8833",
      //   borderColor: "#FB8833", // for Line chart
      // },
      {
        label: "Lux",
        data: [],
        fill: false, // for Line chart
        backgroundColor: "#17A8F5",
        borderColor: "#17A8F5", // for Line chart
      },
    ],
  };

  var co2ChartData = {
    // labels: ["January", "February", "March", "April", "May", "June"],
    labels: [],
    datasets: [
      // {
      //   label: "Sales",
      //   data: [21, 35, 75, 51, 41, 47],
      //   fill: false, // for Line chart
      //   backgroundColor: "#FB8833",
      //   borderColor: "#FB8833", // for Line chart
      // },
      {
        label: "Co2",
        data: [],
        fill: false, // for Line chart
        backgroundColor: "#17A8F5",
        borderColor: "#17A8F5", // for Line chart
      },
    ],
  };

  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    // console.log("BioflyDeviceCharts Only once execution here only...");

    // console.log(id);

    // const apis = "/api/sensorDatas/biofly/hour/1/" + id.deviceIDDetails;

    const currentdatetime = Date.now();
    const Onehourbackdatetime = currentdatetime - 3600000; // get the 24 hour back time
    setValue([new Date(Onehourbackdatetime), new Date(currentdatetime)]);

    // const apis =
    //   `${baseUrl}/api/sensorDatas/biofly/hour/1/` + id.deviceIDDetails;

    // // console.log("apis:", apis);

    const apis =
      `${baseUrl}/api/sensorDatas/biofly/datetimerange/` +
      id.deviceIDDetails +
      `/` +
      Onehourbackdatetime +
      `/` +
      currentdatetime;
    // console.log("apis:", apis);

    fetch(apis, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("data:", data);

        const length = data.length;
        // console.log("length:");
        // console.log(length);

        // console.log("json11111:");
        // console.log(json1[0]._id);

        // labels = [];
        // values = [];
        for (var i = 0; i < length; i++) {
          const date = moment.unix(data[i].timestamp).format("DD/MM/YYYY");
          const time = moment.unix(data[i].timestamp).format("hh:mm:ss");

          temperatureChartData.labels[i] = time + " " + date;
          temperatureChartData.datasets[0].data[i] = data[i].temperature;

          humidityChartData.labels[i] = time + " " + date;
          humidityChartData.datasets[0].data[i] = data[i].humidity;

          luxChartData.labels[i] = time + " " + date;
          luxChartData.datasets[0].data[i] = data[i].lux;

          co2ChartData.labels[i] = time + " " + date;
          co2ChartData.datasets[0].data[i] = data[i].co2;
        }

        setItems(temperatureChartData);
        setItems2(humidityChartData);
        setItems3(luxChartData);
        setItems4(co2ChartData);
      });
  }, []);

  const [selectValue, setSelectValue] = React.useState("");
  const onChange = (event) => {
    const value = event.target.value;
    setSelectValue(value);

    // console.log("value:", value);

    if (value == "One hour") {
      // const apis = "/api/sensorDatas/biofly/hour/1/" + id.deviceIDDetails;

      const apis =
        `${baseUrl}/api/sensorDatas/biofly/hour/1/` + id.deviceIDDetails;

      // console.log("apis:", apis);

      fetch(apis, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("data:", data);

          const length = data.length;
          // console.log("length:");
          // console.log(length);

          // console.log("json11111:");
          // console.log(json1[0]._id);

          // labels = [];
          // values = [];
          for (var i = 0; i < length; i++) {
            const date = moment.unix(data[i].timestamp).format("DD/MM/YYYY");
            const time = moment.unix(data[i].timestamp).format("hh:mm:ss");

            temperatureChartData.labels[i] = time + " " + date;
            temperatureChartData.datasets[0].data[i] = data[i].temperature;

            humidityChartData.labels[i] = time + " " + date;
            humidityChartData.datasets[0].data[i] = data[i].humidity;

            luxChartData.labels[i] = time + " " + date;
            luxChartData.datasets[0].data[i] = data[i].lux;

            co2ChartData.labels[i] = time + " " + date;
            co2ChartData.datasets[0].data[i] = data[i].co2;
          }

          setItems(temperatureChartData);
          setItems2(humidityChartData);
          setItems3(luxChartData);
          setItems4(co2ChartData);
        });
    } else if (value == "One day") {
      // const apis = "/api/sensorDatas/biofly/days/1/" + id.deviceIDDetails;

      const apis =
        `${baseUrl}/api/sensorDatas/biofly/days/1/` + id.deviceIDDetails;

      // console.log("apis:", apis);

      fetch(apis, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("data:", data);

          const length = data.length;
          // console.log("length:");
          // console.log(length);

          // console.log("json11111:");
          // console.log(json1[0]._id);

          // labels = [];
          // values = [];
          for (var i = 0; i < length; i++) {
            const date = moment.unix(data[i].timestamp).format("DD/MM/YYYY");
            const time = moment.unix(data[i].timestamp).format("hh:mm:ss");

            temperatureChartData.labels[i] = time + " " + date;
            temperatureChartData.datasets[0].data[i] = data[i].temperature;

            humidityChartData.labels[i] = time + " " + date;
            humidityChartData.datasets[0].data[i] = data[i].humidity;

            luxChartData.labels[i] = time + " " + date;
            luxChartData.datasets[0].data[i] = data[i].lux;

            co2ChartData.labels[i] = time + " " + date;
            co2ChartData.datasets[0].data[i] = data[i].co2;
          }

          setItems(temperatureChartData);
          setItems2(humidityChartData);
          setItems3(luxChartData);
          setItems4(co2ChartData);
        });
    } else if (value == "One week") {
      // const apis = "/api/sensorDatas/biofly/days/7/" + id.deviceIDDetails;

      const apis =
        `${baseUrl}/api/sensorDatas/biofly/days/7/` + id.deviceIDDetails;

      // console.log("apis:", apis);

      fetch(apis, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("data:", data);

          const length = data.length;
          // console.log("length:");
          // console.log(length);

          // console.log("json11111:");
          // console.log(json1[0]._id);

          // labels = [];
          // values = [];
          for (var i = 0; i < length; i++) {
            const date = moment.unix(data[i].timestamp).format("DD/MM/YYYY");
            const time = moment.unix(data[i].timestamp).format("hh:mm:ss");

            temperatureChartData.labels[i] = time + " " + date;
            temperatureChartData.datasets[0].data[i] = data[i].temperature;

            humidityChartData.labels[i] = time + " " + date;
            humidityChartData.datasets[0].data[i] = data[i].humidity;

            luxChartData.labels[i] = time + " " + date;
            luxChartData.datasets[0].data[i] = data[i].lux;

            co2ChartData.labels[i] = time + " " + date;
            co2ChartData.datasets[0].data[i] = data[i].co2;
          }

          setItems(temperatureChartData);
          setItems2(humidityChartData);
          setItems3(luxChartData);
          setItems4(co2ChartData);
        });
    } else if (value == "One month") {
      // const apis = "/api/sensorDatas/biofly/days/30/" + id.deviceIDDetails;

      const apis =
        `${baseUrl}/api/sensorDatas/biofly/days/30/` + id.deviceIDDetails;

      // console.log("apis:", apis);

      fetch(apis, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("data:", data);

          const length = data.length;
          // console.log("length:");
          // console.log(length);

          // console.log("json11111:");
          // console.log(json1[0]._id);

          // labels = [];
          // values = [];
          for (var i = 0; i < length; i++) {
            const date = moment.unix(data[i].timestamp).format("DD/MM/YYYY");
            const time = moment.unix(data[i].timestamp).format("hh:mm:ss");

            temperatureChartData.labels[i] = time + " " + date;
            temperatureChartData.datasets[0].data[i] = data[i].temperature;

            humidityChartData.labels[i] = time + " " + date;
            humidityChartData.datasets[0].data[i] = data[i].humidity;

            luxChartData.labels[i] = time + " " + date;
            luxChartData.datasets[0].data[i] = data[i].lux;

            co2ChartData.labels[i] = time + " " + date;
            co2ChartData.datasets[0].data[i] = data[i].co2;
          }

          setItems(temperatureChartData);
          setItems2(humidityChartData);
          setItems3(luxChartData);
          setItems4(co2ChartData);
        });
    } else if (value == "One year") {
      // const apis = "/api/sensorDatas/biofly/days/365/" + id.deviceIDDetails;

      const apis =
        `${baseUrl}/api/sensorDatas/biofly/days/365/` + id.deviceIDDetails;

      // console.log("apis:", apis);

      fetch(apis, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("data:", data);

          const length = data.length;
          // console.log("length:");
          // console.log(length);

          // console.log("json11111:");
          // console.log(json1[0]._id);

          // labels = [];
          // values = [];
          for (var i = 0; i < length; i++) {
            const date = moment.unix(data[i].timestamp).format("DD/MM/YYYY");
            const time = moment.unix(data[i].timestamp).format("hh:mm:ss");

            temperatureChartData.labels[i] = time + " " + date;
            temperatureChartData.datasets[0].data[i] = data[i].temperature;

            humidityChartData.labels[i] = time + " " + date;
            humidityChartData.datasets[0].data[i] = data[i].humidity;

            luxChartData.labels[i] = time + " " + date;
            luxChartData.datasets[0].data[i] = data[i].lux;

            co2ChartData.labels[i] = time + " " + date;
            co2ChartData.datasets[0].data[i] = data[i].co2;
          }

          setItems(temperatureChartData);
          setItems2(humidityChartData);
          setItems3(luxChartData);
          setItems4(co2ChartData);
        });
    }
  };

  const [value, setValue] = React.useState([]);

  const checkvalue = () => {
    // setValue1(value);
    // console.log("Date-Time-range:");
    // console.log(value);

    // console.log("fromDate:");
    // console.log(value[0]);

    // console.log("toDate:");
    // console.log(value[1]);

    let dateString0 = value[0];
    let fromDate = Date.parse(dateString0);
    // console.log("fromDate:");
    // console.log(fromDate);

    let dateString1 = value[1];
    let toDate = Date.parse(dateString1);
    // console.log("toDate:");
    // console.log(toDate);

    // let dateString1 = value[0];
    // let date = new Date(dateString1);
    // let result1 = date.getTime();
    // console.log(result1);

    // const apis =
    //   `${baseUrl}/api/sensorDatas/biofly/days/7/` + id.deviceIDDetails;

    const apis =
      `${baseUrl}/api/sensorDatas/biofly/datetimerange/869523050076489/` +
      fromDate +
      `/` +
      toDate;

    // /biofly/datetimerange/:deviceID/:fromdate/:todate
    // console.log("apis:", apis);
    // console.log("apis:", apis);

    // console.log("apis:", apis);

    fetch(apis, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("data:", data);

        const length = data.length;
        // console.log("length:");
        // console.log(length);

        // console.log("json11111:");
        // console.log(json1[0]._id);

        // labels = [];
        // values = [];
        for (var i = 0; i < length; i++) {
          const date = moment.unix(data[i].timestamp).format("DD/MM/YYYY");
          const time = moment.unix(data[i].timestamp).format("hh:mm:ss");

          temperatureChartData.labels[i] = time + " " + date;
          temperatureChartData.datasets[0].data[i] = data[i].temperature;

          humidityChartData.labels[i] = time + " " + date;
          humidityChartData.datasets[0].data[i] = data[i].humidity;

          luxChartData.labels[i] = time + " " + date;
          luxChartData.datasets[0].data[i] = data[i].lux;

          co2ChartData.labels[i] = time + " " + date;
          co2ChartData.datasets[0].data[i] = data[i].co2;
        }

        setItems(temperatureChartData);
        setItems2(humidityChartData);
        setItems3(luxChartData);
        setItems4(co2ChartData);
      });
  };

  return (
    <div className="parent">
      {/* <button type="button" class="btn btn-info" onClick={goBack}>
        Back
      </button> */}

      {/* <div>
        <LineChart />
      </div>
      <div>
        <LineChart />
      </div> */}

      <section className="border text-center mb-4">
        <h4 className="mb-3">History Chart</h4>
        <button type="button" class="btn btn-info" onClick={goBack}>
          Back
        </button>
        <Stack direction="row" spacing={25} alignItems="flex-start">
          <label>Select a Date range</label>
          <DateRangePicker
            value={value}
            onChange={setValue}
            showMeridian
            format="yyyy-MM-dd HH:mm:ss"
            onExited={checkvalue}
            defaultCalendarValue={[
              new Date("2024-01-01 00:00:00"),
              new Date("2024-02-01 23:59:59"),
            ]}
          />
          {/* <label>Select data for last </label> */}
          {/* <select onChange={onChange} className="form-select1"> */}
          {/* <option defaultValue disabled>
            Select days
          </option> */}
          {/* <option value="One hour">One hour</option>
          <option value="One day">One day</option>
          <option value="One week">One week</option>
          <option value="One month">One month</option>
          <option value="One year">One year</option>
        </select> */}
        </Stack>

        <div class="container">
          <div class="row">
            <div class="col-sm-6">
              {items && <DynamicChart items={items} />}
            </div>
            <div class="col-sm-6">
              {items2 && <DynamicChart items={items2} />}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              {items3 && <DynamicChart items={items3} />}
            </div>
            <div class="col-sm-6">
              {items4 && <DynamicChart items={items4} />}
            </div>
          </div>
        </div>

        {/* <div class="container">
          <div class="row row-cols-2">
            <div class="col"> {items && <DynamicChart items={items} />}</div>
            <div class="col">{items2 && <DynamicChart items={items2} />}</div>
            <div class="col">{items3 && <DynamicChart items={items3} />}</div>
            <div class="col"> {items4 && <DynamicChart items={items4} />}</div>
          </div>
        </div> */}
      </section>
    </div>
  );
};

export default BioflyDeviceCharts;
