// import { useProSidebar } from "react-pro-sidebar";

// // import React from 'react'
// import { Bar } from 'react-chartjs-2'
// import React, { useState, useEffect } from "react";

// import { useMemo } from "react";
// import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

// // import React from "react";
// import ReactDOM from "react-dom";
// // import React from "react";
// // import ReactDOM from "react-dom";
// // import ReactDOM from '../react-dom';
// // import './index.css';
// import "../components/styles.css";
// import socketIO from "socket.io-client";

// // import { useEffect, useState }from 'react'
// import { useWorkoutsContext } from "../hooks/useWorkoutsContext"
// import { useAuthContext } from "../hooks/useAuthContext"
// import { useSensorDatasContext } from "../hooks/useSensorDatasContext"

// // components
// import WorkoutDetails from '../components/WorkoutDetails'
// import WorkoutForm from '../components/WorkoutForm'
// import SensorDataDetails from '../components/SensorDataDetails'

// import { io } from 'socket.io-client';
// import { setSeconds } from 'date-fns'

// // import BarChart from '../components/BarChart'

// import { Doughnut } from 'react-chartjs-2';

// import {useState} from 'react';

import React, { useState, useEffect, useRef } from "react";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";

import { useAuthContext } from "../hooks/useAuthContext";
import { useSensorDatasContext } from "../hooks/useSensorDatasContext";

var sensorDataDetails;

var glabels = [];
var gdatas = [];

var employees = [
  {
    id: 1,
    name: "Tommy",
    age: 23,
    city: "New York",
  },
  {
    id: 2,
    name: "Mike",
    age: 27,
    city: "Detroit",
  },
  {
    id: 3,
    name: "Lisa",
    age: 25,
    city: "Chicago",
  },
  {
    id: 4,
    name: "Kane",
    age: 31,
    city: "New York",
  },
  {
    id: 5,
    name: "Mark",
    age: 37,
    city: "Chicago",
  },
];

ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);

const Graphs = () => {
  const { user } = useAuthContext();
  const { dispatch2 } = useSensorDatasContext();
  // const { sensorDatas3, dispatch3 } = useSensorDatasContext()

  // const fetchSensorDataDetails = async (deviceID) => {
  //   const apis = "/api/sensorDatas/test/" + "TLM1003" + "/" + "1000";
  //   const response = await fetch(apis, {
  //     headers: { Authorization: `Bearer ${user.token}` },
  //   });
  //   const json = await response.json();
  //   if (response.ok) {
  //     sensorDataDetails = json;
  //   }

  //   // console.log("sensorDataDetails: ")
  //   // console.log(sensorDataDetails)

  //   for (let i = 0; i < sensorDataDetails.length; i++) {
  //     var temparr = sensorDataDetails[i];
  //     var ttime = temparr.time;
  //     var ttankLevel = temparr.tankLevel;

  //     glabels[i] = ttime;
  //     gdatas[i] = ttankLevel;

  //     // console.log( glabels[i], gdatas[i] );
  //   }
  // };

  useEffect(() => {
    if (user) {
      // fetchSensorDataDetails("TLM1003")
      // console.log("graph data:");
      // console.log( glabels[0], gdatas[0] );
    }
  }, [dispatch2, user]);

  // const { toggleSidebar, collapseSidebar, broken, collapsed } = useProSidebar();
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    // console.log('Only once execution here only...');

    if (user) {
      // fetchSensorDataDetails("TLM1003");
      // console.log("graph data:");
      // console.log( glabels[0], gdatas[0] );
    }
    // toggleSidebar();
  }, []);

  var [data, setData] = useState({
    labels: [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    // labels: [
    //   glabels[0],
    //   glabels[1],
    //   glabels[2],
    //   glabels[3],
    //   glabels[4],
    //   glabels[5],
    //   glabels[6],
    //   glabels[7],
    //   glabels[8],
    //   glabels[9],
    //   glabels[10],
    //   glabels[11],
    // ],
    datasets: [
      {
        label: "Temperature History: daily",
        data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 190],
        // data: [
        //   gdatas[0],
        //   gdatas[1],
        //   gdatas[2],
        //   gdatas[3],
        //   gdatas[4],
        //   gdatas[5],
        //   gdatas[6],
        //   gdatas[7],
        //   gdatas[8],
        //   gdatas[9],
        //   gdatas[10],
        //   gdatas[11],
        // ],

        backgroundColor: "skyblue",
        borderColor: "green",
        tension: 0.4,
        fill: false,
        pointStyle: "dot",
        pointBorderColor: "blue",
        pointBackgroundColor: "#fff",
        showLine: true,
      },
    ],
  });

  return (
    <div>
      <main>
        <h1 style={{ color: "black", marginLeft: "5rem" }}>Chart Page</h1>

        {/* <div className="sensorValueBoxes"> */}

        <div style={{ width: "600px", height: "350px", alignContent: "left" }}>
          <Line data={data}></Line>
        </div>

        <div style={{ width: "600px", height: "350px", alignContent: "left" }}>
          <Line data={data}></Line>
        </div>

        {/* <div style={{width:'600px', height:'350px', alignContent: "left"}}>
    <Line data={data}></Line>
</div> */}

        {/* // </div> */}
      </main>

      <div>
        {employees.map((employee) => {
          const list = (
            <>
              <ul>
                <li>Id: {employee.id}</li>
                <li>Name: {employee.name}</li>
                <li>Age: {employee.age}</li>
                <li>City: {employee.city}</li>
              </ul>
              <hr />
            </>
          );
          return list;
        })}
      </div>
    </div>

    //   // <div className="home">

    // //  <div>

    //             {/* <div className='sensor-app-container'>
    //           <div className='sensor-display-container'>
    //             <div className='sensor-display'>°C</div>
    //           </div>
    //         </div>

    //         <div className='sensor-app-container'>
    //           <div className='sensor-display-container'>
    //             <div className='sensor-display'>°C</div>
    //           </div>
    //         </div> */}

    //         {/* <div className="App" style={{width:'800px', height:'800px'}}> */}

    //  {/* <div style={{width:'800px', height:'800px'}}> */}

    //     //   <Line data={data}></Line>
    //     // </div>
    //       // </div>
  );
};

export default Graphs;
