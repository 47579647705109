import React, { useState } from "react";
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import { useLogin } from "../hooks/useLogin";
import { useNavigate } from "react-router-dom";
import logo2 from "../images/iot.png";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { login, error, isLoading } = useLogin();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      alert("Please fill in all fields");
      return;
    }

    await login(email, password);
  };

  const navigateToSignup = () => {
    navigate("/Signup");
  };

  return (
    <form onSubmit={handleSubmit}>
      <MDBContainer fluid className="p-3 my-5 h-custom">
        <MDBRow>
          <MDBCol col="10" md="6">
            <img src={logo2} className="img-fluid" alt="IoT illustration" />
          </MDBCol>

          <MDBCol col="4" md="6">
            <div className="d-flex flex-row align-items-center justify-content-center mb-4">
              <p className="lead fw-normal mb-0 me-3">Sign in</p>
            </div>

            <MDBInput
              wrapperClass="mb-4"
              label="Email address"
              id="email"
              type="email"
              size="lg"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <MDBInput
              wrapperClass="mb-4"
              label="Password"
              id="password"
              type={showPassword ? "text" : "password"}
              size="lg"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />

            <div className="d-flex justify-content mb-4">
              <MDBCheckbox
                id="showPassword"
                checked={showPassword}
                onChange={() => setShowPassword((prev) => !prev)}
              />
              <label htmlFor="showPassword" className="ms-2">
                Show Password
              </label>
            </div>

            {error && <p className="text-danger">{error}</p>}

            <div className="d-flex justify-content-between mb-4">
              <MDBCheckbox
                name="flexCheck"
                id="flexCheckDefault"
                label="Remember me"
              />
              <a href="/forgot-password">Forgot password?</a>
            </div>

            <div className="text-center text-md-start mt-4 pt-2">
              <MDBBtn
                type="submit"
                className="mb-0 px-5"
                size="lg"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Login"}
              </MDBBtn>
              <p className="small fw-bold mt-2 pt-1 mb-2">
                Don't have an account?{" "}
                <span
                  onClick={navigateToSignup}
                  className="link-danger"
                  style={{ cursor: "pointer" }}
                >
                  Signup here
                </span>
              </p>
            </div>
          </MDBCol>
        </MDBRow>

        <div className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">
          <div className="text-white mb-3 mb-md-0">
            Copyright © 2022. All rights reserved.
          </div>

          <div className="text-white mb-3 mb-md-0">
            A product of Vincenzo Technology PVT. LTD.
          </div>
        </div>
      </MDBContainer>
    </form>
  );
}

export default LoginPage;
