const ProgressBar = (props) => {
  const { LabelName, bgcolor, completed } = props;

  const containerStyles = {
    height: 20,
    width: "90%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    margin: 10,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: "inherit",
    textAlign: "right",
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };

  const labelStyles1 = {
    padding: 5,
    color: bgcolor,
    fontWeight: "bold",
  };


  return (
    <div style={labelStyles1}>
        <p><b>{LabelName}</b></p>
    
    <div style={containerStyles}>
    
      <div style={fillerStyles}>
      
        <span style={labelStyles}>{`${completed}%`}</span>
      </div>
    </div>
    </div>
  );
};

export default ProgressBar;
