import React, { useState, useEffect, useRef } from "react";
import {
  createSearchParams,
  Link,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";

import { useAuthContext } from "../hooks/useAuthContext";

// import Switch from "react-button-switch";
// import Switch from 'react-input-switch';

const baseUrl = process.env.REACT_APP_API_URL;

const EzytrackUserDetails = () => {
  const { user } = useAuthContext();

  // const { state } = useLocation();
  // const { json1 } = state;

  // const json1 = props.items[0];

  // const [count, setCount] = useState('');

  // const [dataas, setdataas] = useState("green");

  // let [data, setData] = useState(0);

  // const [users, setUsers] = useState([]);

  const [users, setUsers] = useState([]);

  // const fetchSensorDataDetail = async (deviceID) => {
  const navigate = useNavigate();

  // const number = "869523050076489";

  // const goBack = () => {

  const navigateToEzytrackSensorBox = (deviceIDDetails) => {
    navigate("/EzytrackDeviceDetail", {
      state: { id: { deviceIDDetails } },
    });
  };

  // const navigateToShmSensorCharts = (deviceIDDetails) => {
  //   navigate("/ShmDeviceCharts", {
  //     state: { id: { deviceIDDetails } },
  //   });
  // };

  // const navigateHome1 = (number) => {
  //   console.log("deviceType is biofly ");

  //   navigate("/BioflyDeviceDetail", {
  //     state: { id: { number } },
  //   });
  // };
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    // console.log('Only once execution here only...');

    // console.log("props: ", json1);
    // console.log("deviceIDDetails: ", json1.deviceIDDetails);
    // console.log("role: ", json1.role);
    // console.log("deviceType: ", json1.deviceType);

    // setUsers(json1.deviceIDDetails);

    // console.log("users1", props.users1);

    // const apis = "/api/deviceAssigned/" + user.email;

    const apis = `${baseUrl}/api/deviceAssigned/` + user.email;

    fetch(apis, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        const data1 = data[0];
        // console.log("data:", data1);
        // console.log("data.role:", data1.role);
        // console.log("data.deviceIDDetails:", data1.deviceIDDetails);
        // setItems(data);
        setUsers(data1.deviceIDDetails);
      });
  }, []);

  // const navigateToContacts = () => {
  //     // 👇️ navigate to /contacts
  //     navigate('/DeviceDetail');
  // };

  // var used =  users[0]
  // let user = '{"name": "Jason", "age": 25, "friends": [0,1,2]}';

  // user = JSON.parse(used);

  // console.log(user.deviceIDDetails);

  // console.log(user)

  // const navigateHome = (id) => {

  //     console.log("temp:")
  //     console.log(temp)

  //     // setData("tyu");
  //     //   👇️ navigate to /
  //     navigate('/DeviceDetail', {
  //         state: {
  //             deviceId: "123",
  //             id: temp
  //         }
  //     });
  // };

  // const incrementCount6 = () => {
  //   setCount(count + 1);
  //   setCount(count + 2);
  //   setCount(count + 3);
  // }

  // const navigateHome1 = (deviceID) => {

  //     temp = deviceID;

  //     console.log("temp:")
  //     console.log(temp)

  //     console.log("deviceID:")
  //     console.log(deviceID)

  //     setData(deviceID)

  //     console.log("data:")
  //     console.log(data)

  //     // navigateHome();
  // }

  // useEffect(() => {
  //     console.log("data11:")
  //     console.log(data)
  // }, [data])

  // const posts = React.useRef(null);

  // useEffect(() => {
  //     posts.current = 'values';
  //     console.log(posts.current)
  // }, [])

  const cardContainer = {
    height: "120px",
    width: "220px",
    background: "Cyan",
    borderRadius: "20px",
    // paddingLeft: "80px"
    // boxShadow: 10px 10px 38px 0px rgba(0, 0, 0, 0.75);
  };

  const enButton = {
    backgroundColor: "#4CAF50",
    border: "none",
    color: "white",
    padding: "15px 32px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "16px",
    margin: "4px 2px",
    cursor: "pointer",
  };

  return (
    <div className="parent">
      <>
        {users.map((deviceIDDetails) => (
          <div id="div_top_hypers" key={deviceIDDetails}>
            <ul id="ul_top_hypers">
              <li>
                <div className="child">
                  <div className="cardSensor-bigcontainer">
                    <br />
                    <hr />
                    <b>DeviceID: {deviceIDDetails}</b>

                    <hr />
                    <div>
                      <br />
                      <br />
                      <button
                        href="#"
                        className="ebutton"
                        onClick={() => {
                          navigateToEzytrackSensorBox(deviceIDDetails);
                        }}
                      >
                        <p>View Devices</p>
                      </button>

                      {/* <Switch  /> */}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        ))}
      </>
    </div>
  );
};

export default EzytrackUserDetails;
