import React, { useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBTextArea,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBTypography,
  MDBIcon,
} from "mdb-react-ui-kit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:5000"; // Fallback to localhost if environment variable is missing

const App = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setError(null); // Reset error on input change
  };

  const validateForm = () => {
    const { firstName, email, subject, message } = formData;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!firstName || !email || !subject || !message) {
      return "All fields are required.";
    }
    if (!emailRegex.test(email)) {
      return "Please enter a valid email address.";
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    setLoading(true);
    setSuccessMessage(null);
    try {
      const response = await axios.post(`${baseUrl}/api/send-email`, formData);
      setSuccessMessage(response.data.message || "Email sent successfully!");
      setFormData({ firstName: "", email: "", subject: "", message: "" }); // Reset form
    } catch (error) {
      console.error("Error sending email:", error);
      setError("There was an error sending the email.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="parent">
    {/* <div> */}
      <MDBCard className="text-center mb-4">
        <MDBCardBody>
          <MDBTypography tag="h3" className="mb-4">
            Contact Us
          </MDBTypography>
          <MDBTypography tag="h5" className="mb-4">
            If you encounter any issues, please report them to us via email or
            the following methods for quicker resolution.
          </MDBTypography>

          <MDBRow>
            <MDBCol lg="7">
              <form onSubmit={handleSubmit}>
                <MDBRow className="mb-4">
                  <MDBCol md="6">
                    <MDBInput
                      label="First name"
                      id="form3FirstName"
                      name="firstName"
                      aria-label="Enter your first name"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </MDBCol>
                  <MDBCol md="6">
                    <MDBInput
                      label="Email Address"
                      id="form3Email"
                      type="email"
                      name="email"
                      aria-label="Enter your email address"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </MDBCol>
                </MDBRow>
                <MDBInput
                  type="text"
                  label="Subject"
                  id="form3Subject"
                  name="subject"
                  aria-label="Enter the subject of your message"
                  value={formData.subject}
                  onChange={handleChange}
                  wrapperClass="mb-4"
                  required
                />
                <MDBTextArea
                  label="Message"
                  id="form3Textarea"
                  name="message"
                  aria-label="Enter your message"
                  value={formData.message}
                  onChange={handleChange}
                  wrapperClass="mb-4"
                  required
                />
                {error && <div className="alert alert-danger">{error}</div>}
                {successMessage && (
                  <div className="alert alert-success">{successMessage}</div>
                )}
                <MDBBtn color="primary" type="submit" disabled={loading}>
                  {loading ? "Sending..." : "Send"}
                </MDBBtn>
              </form>
            </MDBCol>
            <MDBCol lg="5">
              <ul className="list-unstyled">
                <li className="mb-3">
                  <MDBIcon
                    icon="map-marker-alt"
                    size="2x"
                    className="text-primary"
                  />
                  <p>
                    <small>Budheswari Colony, Bhubaneswar, Odisha 751006</small>
                  </p>
                </li>
                <li className="mb-3">
                  <MDBIcon icon="phone" size="2x" className="text-primary" />
                  <p>
                    <small>+91 8270441234</small>
                  </p>
                </li>
                <li className="mb-3">
                  <MDBIcon icon="envelope" size="2x" className="text-primary" />
                  <p>
                    <small>sarojflame@gmail.com</small>
                  </p>
                </li>
              </ul>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </div>
  );
};

export default App;
