import React, { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";

const baseUrl = process.env.REACT_APP_API_URL;

const Home = () => {
  const { user } = useAuthContext();
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clientDisabled, setClientDisabled] = useState(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const fetchUserData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${baseUrl}/api/deviceAssigned/${user.email}`,
          {
            headers: { Authorization: `Bearer ${user.token}` },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch data");

        const data = await response.json();
        const data1 = data[0];

        if (data1.role === "admin") {
          navigate("/Admin");
        } else if (data1.role === "client") {
          if (data1.activation === "false") {
            setClientDisabled(true);
          } else {
            navigateToUserDetails(data1.deviceType);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const navigateToUserDetails = (deviceType) => {
      switch (deviceType) {
        case "biofly":
          navigate("/BioflyUserDetails");
          break;
        case "shm":
          navigate("/ShmUserDetails");
          break;
        case "ezytrack":
          navigate("/EzytrackUserDetails");
          break;
        case "ezymoteHA3L1F":
          navigate("/EzymoteUserDetails");
          break;
        default:
          console.warn("Unknown device type:", deviceType);
      }
    };

    fetchUserData();
  }, [navigate, user.email, user.token]);

  if (loading) return <div className="mainPage">Loading...</div>;
  if (error) return <div className="mainPage">Error: {error}</div>;
  if (clientDisabled) return <div className="mainPage">Your account is disabled. Please contact support.</div>;

  return <div></div>;
};

export default Home;
