import React, { useState, useEffect, useRef } from "react";
import { GiCeilingLight } from "react-icons/gi";
import { HiLightBulb } from "react-icons/hi";
import { PiFanFill } from "react-icons/pi";
import { SiHomeassistant } from "react-icons/si";
import { BsLampFill } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { useAuthContext } from "../hooks/useAuthContext";
import "./Sw4r.css";
import Switch from "react-switch";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import io from "socket.io-client";
import WifiStrengthIndicator from "./WifiStrengthIndicator";

const baseUrl = process.env.REACT_APP_API_URL;
const socket = io.connect(baseUrl);

const Sw4r = ({ deviceID }) => {
  const { user } = useAuthContext();
  const [currentData, setCurrentData] = useState({});
  const [fanSpeed, setFanSpeed] = useState(0);
  const [loadStates, setLoadStates] = useState({
    load1: false,
    load2: false,
    load3: false,
    fan1: false,
  });
  // const [deviceID, setDeviceID] = useState(deviceID);
  const [labels, setLabels] = useState({
    load1: "Ceiling Light 1",
    load2: "Ceiling Light 2",
    load3: "Ceiling Light 3",
    fan1: "Fan",
    fanSpeed: "Fan Speed",
  });
  const [icons, setIcons] = useState({
    load1: "GiCeilingLight",
    load2: "BsLampFill",
    load3: "HiLightBulb",
    fan1: "PiFanFill",
  });
  const [isEditing, setIsEditing] = useState(false);

  const dataFetchedRef = useRef(false);

  const iconComponents = {
    GiCeilingLight: <GiCeilingLight size={40} />,
    BsLampFill: <BsLampFill size={40} />,
    HiLightBulb: <HiLightBulb size={40} />,
    PiFanFill: <PiFanFill size={40} />,
  };

  // Function to update device state
  const updateDeviceState = async (updates) => {
    try {
      const response = await fetch(
        `${baseUrl}/api/sensorDatas/ezymoteHA3L1FMQTT`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ...currentData, ...updates }),
        }
      );
      if (!response.ok) throw new Error("Failed to update device state");
    } catch (error) {
      console.error("Error updating device state:", error);
    }
  };

  // Fetch sensor data from the server
  const fetchSensorData = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/api/sensorDatas/ezymoteHA3L1F/${deviceID}/1`,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch sensor data");
      const json = await response.json();
      setCurrentData(json[0]);
      setLoadStates({
        load1: json[0].load1 === "on",
        load2: json[0].load2 === "on",
        load3: json[0].load3 === "on",
        fan1: json[0].fan1 === "on",
      });
      setFanSpeed(parseInt(json[0].fan1Speed, 10));
    } catch (error) {
      console.error("Error fetching sensor data:", error);
    }
  };

  // Fetch labels and icons for customization
  const fetchLabelsAndIcons = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/deviceLabels/${deviceID}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      if (!response.ok) throw new Error("Failed to fetch labels and icons");
      const { labels: fetchedLabels, icons: fetchedIcons } =
        await response.json();
      setLabels(fetchedLabels);
      setIcons(fetchedIcons);
    } catch (error) {
      console.error("Error fetching labels and icons:", error);
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetchSensorData();
    fetchLabelsAndIcons();
  }, [deviceID]);

  useEffect(() => {
    socket.on(deviceID, (data) => {
      setCurrentData(data);
      setLoadStates({
        load1: data.load1 === "on",
        load2: data.load2 === "on",
        load3: data.load3 === "on",
        fan1: data.fan1 === "on",
      });
      setFanSpeed(parseInt(data.fan1Speed, 10));
    });
    return () => {
      socket.off(deviceID);
    };
  }, [socket, deviceID]);

  const handleLoadToggle = async (loadKey) => {
    const newState = !loadStates[loadKey];
    setLoadStates({ ...loadStates, [loadKey]: newState });
    await updateDeviceState({ [loadKey]: newState ? "on" : "off" });
  };

  const handleFanSpeedChange = async (speed) => {
    setFanSpeed(speed);
    await updateDeviceState({ fan1Speed: speed });
  };

  const handleLabelChange = (key, newLabel) => {
    setLabels({ ...labels, [key]: newLabel });
  };

  const handleIconChange = (key, newIcon) => {
    setIcons({ ...icons, [key]: newIcon });
  };

  const saveLabelsAndIcons = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/deviceLabels/${deviceID}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({ deviceID, labels, icons }),
      });
      if (!response.ok) throw new Error("Failed to save labels and icons");
      alert("Labels and icons updated successfully");
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving labels and icons:", error);
    }
  };

  return (
    <div className="parent">
      <div className="child1">
        <div className="homeautomation-container">
          <p className="welcome-message">
            <SiHomeassistant size={50} style={{ color: "#08C2F3" }} />
            Welcome, <b>{user.userName}</b>
          </p>
          <center>
            Device ID: {currentData.deviceID}{" "}
            <FaEdit
              size={20}
              style={{
                cursor: "pointer",
                color: isEditing ? "red" : "#08C2F3",
              }}
              onClick={() => setIsEditing(!isEditing)}
            />
          </center>
          <WifiStrengthIndicator csq={currentData.csq} />
        </div>
      </div>

      {Object.entries(loadStates).map(([loadKey, isOn]) => (
        <div className="child1" key={loadKey}>
          <div className="homeautomation-buttoncontainer">
            {React.cloneElement(iconComponents[icons[loadKey]], {
              style: { color: isOn ? "#08C2F3" : "gray" },
            })}
            <Switch
              checked={isOn}
              onChange={() => handleLoadToggle(loadKey)}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              height={12}
              width={40}
            />
            <center>
              {isEditing ? (
                <>
                  <input
                    type="text"
                    size="12"
                    value={labels[loadKey]}
                    onChange={(e) => handleLabelChange(loadKey, e.target.value)}
                  />
                  <select
                    value={icons[loadKey]}
                    onChange={(e) => handleIconChange(loadKey, e.target.value)}
                  >
                    <option value="GiCeilingLight">Ceiling Light</option>
                    <option value="BsLampFill">Lamp</option>
                    <option value="HiLightBulb">Bulb</option>
                    <option value="PiFanFill">Fan</option>
                  </select>
                </>
              ) : (
                <b>{labels[loadKey]}</b>
              )}
            </center>
          </div>
        </div>
      ))}

      <div className="child1">
        <div className="homeautomation-fanregulatorcontainer">
          <Box sx={{ width: 250 }}>
            <Slider
              value={Number.isFinite(fanSpeed) ? fanSpeed : 0}
              onChange={(e, value) => setFanSpeed(value)}
              onChangeCommitted={(e, value) => handleFanSpeedChange(value)}
              aria-label="Fan Speed"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={0}
              max={5}
            />
          </Box>
          <center>
            {isEditing ? (
              <input
                type="text"
                size="12"
                value={labels.fanSpeed}
                onChange={(e) => handleLabelChange("fanSpeed", e.target.value)}
              />
            ) : (
              <b>{labels.fanSpeed}</b>
            )}
          </center>
        </div>
      </div>

      {isEditing && (
        <div className="child1">
          <button onClick={saveLabelsAndIcons}>Save Labels and Icons</button>
        </div>
      )}
    </div>
  );
};

export default Sw4r;
