// import { useLocation } from "react-router-dom";
// import React from "react";
// // import { useForm } from "react-hook-form";

// // import '../index.css';

// // import RegisterForm from "../components/RegisterForm.js";
// // import BioflySensorBox from "../components/bioflySensorBox.js";

// import EzytrackSensorBox from "../components/EzytrackSensorBox.js";

// // import EzytrackSensorBoxcopy from "../components/EzytrackSensorBoxcopy.js";

// import { Route, Link, Routes, useNavigate } from "react-router-dom";

// import { useAuthContext } from "../hooks/useAuthContext.js";

// // import Sw4rr from "../components/Sw4r.js";

// const baseUrl = process.env.REACT_APP_API_URL;

// const EzytrackDeviceDetail = () => {
//   const { user } = useAuthContext();

//   const { state } = useLocation();
//   const { id } = state;
//   // console.log("id............");
//   // console.log(id);

//   // console.log("user:");
//   // console.log(user);

//   //   console.log("deviceID:");
//   // console.log(id.number);

//   // console.log("deviceID:");
//   // console.log(id.number);

//   // console.log(id.state)

//   // const location = useLocation();

//   // console.log("location:")
//   // console.log(location)

//   // const [searchparams] = useSearchParams();

//   // console.log(searchparams.get("id"));

//   // console.log("deviceID:")
//   // console.log(deviceID)

//   const navigate = useNavigate();
//   const goBack = () => {
//     navigate(-1);
//   };

//   return (
//     <div className="parent">
//       {/* <button type="button" class="btn btn-info" onClick={goBack}>
//         Back
//       </button> */}
//       {/* <> */}
//       {/* <div>    view url search params </div> */}
//       {/* id is {searchparams.state.id} */}
//       {/* id is {location.state.deviceId} */}
//       {/* </> */}

//       {/* <BioflySensorBox deviceID={id.number} /> */}

//       <EzytrackSensorBox deviceID={id.deviceIDDetails} />

//       {/* <Sw4rr deviceID={id.number} /> */}

//       {/* <BioflyBox deviceID={deviceIDName}/> */}
//     </div>
//   );
// };

// export default EzytrackDeviceDetail;

import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import EzytrackSensorBox from "../components/EzytrackSensorBox.js";

const EzytrackDeviceDetail = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const deviceID = state?.id?.deviceIDDetails;

  return (
    <div className="parent">
      {deviceID ? (
        <EzytrackSensorBox deviceID={deviceID} />
      ) : (
        <p>Device details not available.</p>
      )}
      {/* Back button, if needed */}
      {/* <button type="button" className="btn btn-info" onClick={() => navigate(-1)}>
        Back
      </button> */}
    </div>
  );
};

export default EzytrackDeviceDetail;
