import React, { useState, useMemo, useEffect, useRef } from "react";
import { Line, PolarArea } from "react-chartjs-2";
import { useAuthContext } from "../hooks/useAuthContext";

import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
// import { Box, Button } from "@mui/material";
// import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import { jsPDF } from "jspdf"; //or use your library of choice here
// import autoTable from "jspdf-autotable";

// import moment from "moment";

// const blankdata = [
//   {
//     _id: "649ffc2995a37789d87f1577",
//     deviceID: "869523050076489",
//     deviceType: "biofly",
//     csq: "21",
//     batVoltage: "11.7",
//     temperature: "25",
//     humidity: "70",
//     co2: "0.00",
//     lux: "0.00",
//     temperatureT: "0",
//     humidityT: "0",
//     co2T: "0",
//     temperatureControlState: "enable",
//     humidityControlState: "enable",
//     co2ControlState: "enable",
//     airConditionerState: "off",
//     humidifierState: "on",
//     exhaustFanState: "off",
//     warning: "0",
//     error: "0",
//     timestamp: "1688206362",
//     createdAt: "2023-07-01T10:12:57.569Z",
//     updatedAt: "2023-07-01T10:12:57.569Z",
//     __v: 0,
//   },
//   // {
//   //   name: {
//   //     firstName: 'Joe',
//   //     lastName: 'Doe',
//   //   },
//   //   address: '566 Brakus Inlet',
//   //   city: 'South Linda',
//   //   state: 'West Virginia',
//   // },
//   // {
//   //   name: {
//   //     firstName: 'Kevin',
//   //     lastName: 'Vandy',
//   //   },
//   //   address: '722 Emie Stream',
//   //   city: 'Lincoln',
//   //   state: 'Nebraska',
//   // },
//   // {
//   //   name: {
//   //     firstName: 'Joshua',
//   //     lastName: 'Rolluffs',
//   //   },
//   //   address: '32188 Larkin Turnpike',
//   //   city: 'Charleston',
//   //   state: 'South Carolina',
//   // },
//   // {
//   //   name: {
//   //     firstName: 'Joshua',
//   //     lastName: 'Rolluffs',
//   //   },
//   //   address: '32188 Larkin Turnpike',
//   //   city: 'Charleston',
//   //   state: 'South Carolina',
//   // },
//   // {
//   //   name: {
//   //     firstName: 'Joshua',
//   //     lastName: 'Rolluffs',
//   //   },
//   //   address: '32188 Larkin Turnpike',
//   //   city: 'Charleston',
//   //   state: 'South Carolina',
//   // },
// ];

const DynamicTables = (props) => {
  // let [data1, setData1] = useState();

  // setData1(props.items);
  // const { user } = useAuthContext();

  const columns1 = useMemo(
    () => [
      // {
      //   accessorKey: moment.unix("timestamp").format("DD/MM/YYYY"), //access nested data with dot notation
      //   header: "DATE",
      // },
      {
        accessorKey: "createdAt",
        header: "LOGGED ON",
      },
      {
        accessorKey: "timestamp",
        header: "TIMESTAMP",
      },
      {
        accessorKey: "timestampToDate",
        header: "DATE",
      },
      {
        accessorKey: "timestampToTime",
        header: "TIME",
      },
      {
        accessorKey: "temperature",
        header: "TEMPERATURE",
      },
      {
        accessorKey: "humidity",
        header: "HUMIDITY",
      },
      {
        accessorKey: "co2",
        header: "CO2",
      },
      {
        accessorKey: "lux",
        header: "LUX",
      },
      {
        accessorKey: "csq",
        header: "CSQ",
      },
    ],
    []
  );

  const dataFetchedRef = useRef(false);
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    // console.log("DynamicTables Only once execution here only...");

    // setData1(blankdata);
    // console.log("items1: ", props.items);
  }, []);

  const divStyles1 = {
    // backgroundColor: "teal",
    // padding: 10,
    // marginTop: 60,
    // marginLeft: 100,
    height: 420,
    width: 580,
  };

  return (
    // <div style={divStyles1}>
    //   <h3 className="section-title">{props.items.datasets[0].label} Chart</h3>
    //   <Line data={props.items} />
    // </div>

    <div>
      <MaterialReactTable columns={columns1} data={props.items} />;
    </div>
  );
};
export default DynamicTables;
