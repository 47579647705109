// // import React from 'react'
// import { Bar } from 'react-chartjs-2'
import React, { useState, useRef, useEffect } from "react";

// import { useMemo } from "react";
// import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

// // import React from "react";
// import ReactDOM from "react-dom";
// // import React from "react";
// // import ReactDOM from "react-dom";
// // import ReactDOM from '../react-dom';
// // import './index.css';
// import "../components/styles.css";
// import socketIO from "socket.io-client";

// // import { useEffect, useState }from 'react'
// import { useWorkoutsContext } from "../hooks/useWorkoutsContext"
// import { useAuthContext } from "../hooks/useAuthContext"
// import { useSensorDatasContext } from "../hooks/useSensorDatasContext"

// // components
// import WorkoutDetails from '../components/WorkoutDetails'
// import WorkoutForm from '../components/WorkoutForm'
// import SensorDataDetails from '../components/SensorDataDetails'

// import { io } from 'socket.io-client';
// import { setSeconds } from 'date-fns'

// // import BarChart from '../components/BarChart'

// import { useLocation, useSearchParams } from "react-router-dom";

import { useLocation, useNavigate } from "react-router-dom";

// import React from "react";
// import { useForm } from "react-hook-form";

// import '../index.css';

// import RegisterForm from "../components/RegisterForm.js";
// import BioflySensorBox from "../components/bioflySensorBox.js";

// import ShmSensorBox from "../components/ShmSensorBox.js";

import DynamicTables from "../components/DynamicTables";

import { useAuthContext } from "../hooks/useAuthContext.js";

import moment from "moment";

// for date time range picker only
import { DateRangePicker, Stack } from "rsuite";
import "../components/DateRangePickerstyles.css";
// for date time range picker only end

import { CSVLink, CSVDownload } from "react-csv";
import { now } from "lodash";

const baseUrl = process.env.REACT_APP_API_URL;

// var labels123 = ["January", "February", "March", "April", "May", "June"];
var temperatureChartData = {
  // labels: ["January", "February", "March", "April", "May", "June"],
  labels: [],
  datasets: [
    // {
    //   label: "Sales",
    //   data: [21, 35, 75, 51, 41, 47],
    //   fill: false, // for Line chart
    //   backgroundColor: "#FB8833",
    //   borderColor: "#FB8833", // for Line chart
    // },
    {
      label: "Temperature",
      data: [],
      fill: false, // for Line chart
      backgroundColor: "#17A8F5",
      borderColor: "#17A8F5", // for Line chart
    },
  ],
};

var humidityChartData = {
  // labels: ["January", "February", "March", "April", "May", "June"],
  labels: [],
  datasets: [
    // {
    //   label: "Sales",
    //   data: [21, 35, 75, 51, 41, 47],
    //   fill: false, // for Line chart
    //   backgroundColor: "#FB8833",
    //   borderColor: "#FB8833", // for Line chart
    // },
    {
      label: "Humidity",
      data: [],
      fill: false, // for Line chart
      backgroundColor: "#17A8F5",
      borderColor: "#17A8F5", // for Line chart
    },
  ],
};

var luxChartData = {
  // labels: ["January", "February", "March", "April", "May", "June"],
  labels: [],
  datasets: [
    // {
    //   label: "Sales",
    //   data: [21, 35, 75, 51, 41, 47],
    //   fill: false, // for Line chart
    //   backgroundColor: "#FB8833",
    //   borderColor: "#FB8833", // for Line chart
    // },
    {
      label: "Lux",
      data: [],
      fill: false, // for Line chart
      backgroundColor: "#17A8F5",
      borderColor: "#17A8F5", // for Line chart
    },
  ],
};

var co2ChartData = {
  // labels: ["January", "February", "March", "April", "May", "June"],
  labels: [],
  datasets: [
    // {
    //   label: "Sales",
    //   data: [21, 35, 75, 51, 41, 47],
    //   fill: false, // for Line chart
    //   backgroundColor: "#FB8833",
    //   borderColor: "#FB8833", // for Line chart
    // },
    {
      label: "Co2",
      data: [],
      fill: false, // for Line chart
      backgroundColor: "#17A8F5",
      borderColor: "#17A8F5", // for Line chart
    },
  ],
};

const BioflyDataInTables = () => {
  const { user } = useAuthContext();

  const { state } = useLocation();
  const { id } = state;
  // console.log("id............");
  // console.log(id);

  // console.log("user:");
  // console.log(user);

  //   console.log("deviceID:");
  // console.log(id.number);

  // console.log("deviceID123:");
  // console.log(id.number);

  // console.log(id.state)

  // const location = useLocation();

  // console.log("location:")
  // console.log(location)

  // const [searchparams] = useSearchParams();

  // console.log(searchparams.get("id"));

  // console.log("deviceID:")
  // console.log(deviceID)

  const [items, setItems] = useState();
  const [items2, setItems2] = useState();
  const [items3, setItems3] = useState();
  const [items4, setItems4] = useState();

  const [value, setValue] = React.useState([]);

  const [fromDateString, setfromDateString] = React.useState("");
  const [toDateString, settoDateString] = React.useState("");

  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    // console.log('Only once execution here only...');

    // console.log(id);

    // console.log("deviceIDDetails: ", json1.deviceIDDetails);
    // console.log("role: ", json1.role);
    // console.log("deviceType: ", json1.deviceType);

    // setUsers(json1.deviceIDDetails);

    // console.log("users1", props.users1);

    // const apis = "/api/deviceAssigned/" + user.email;

    // fetch(apis, {
    //   headers: { Authorization: `Bearer ${user.token}` },
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     const data1 = data[0];
    //     // console.log("data:", data1);
    //     // console.log("data.role:", data1.role);
    //     console.log("data.deviceIDDetails:", data1.deviceIDDetails);
    //     // setItems(data);
    //     setUsers(data1.deviceIDDetails);
    //   });

    // const apis = "/api/sensorDatas/biofly/hour/1/" + id.deviceIDDetails;

    // const apis =
    //   `${baseUrl}/api/sensorDatas/biofly/days/365/` + id.deviceIDDetails;

    const currentdatetime = Date.now();

    // const Twentyfourhourbackdatetime = currentdatetime - 86400000; // get the 24 hour back time
    // setValue([new Date(currentdatetime), new Date(Twentyfourhourbackdatetime)]);

    const Onehourbackdatetime = currentdatetime - 3600000; // get the 24 hour back time
    setValue([new Date(Onehourbackdatetime), new Date(currentdatetime)]);

    // console.log("currentdatetime1:");
    // console.log(currentdatetime);
    // console.log("Onehourbackdatetime:");
    // console.log(Onehourbackdatetime);

    // let dateString1 = value[0];
    // let date = new Date(dateString1);
    // let result1 = date.getTime();
    // console.log(result1);

    // const apis =
    //   `${baseUrl}/api/sensorDatas/biofly/days/7/` + id.deviceIDDetails;

    // const apis =
    //   `${baseUrl}/api/sensorDatas/biofly/datetimerange/869523050076489/` +
    //   Twentyfourhourbackdatetime +
    //   `/` +
    //   currentdatetime;

    const apis =
      `${baseUrl}/api/sensorDatas/biofly/datetimerange/` +
      id.deviceIDDetails +
      `/` +
      Onehourbackdatetime +
      `/` +
      currentdatetime;
    // console.log("apis:", apis);

    fetch(apis, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("data:", data);

        setItems(data);
      });
  }, []);

  const checkvalue = () => {
    // setValue1(value);
    // console.log("Date-Time-range:");
    // console.log(value);

    // console.log("fromDate:");
    // console.log(value[0]);

    // console.log("toDate:");
    // console.log(value[1]);

    let dateString0 = value[0];
    let fromDate = Date.parse(dateString0);
    // console.log("fromDate:");
    // console.log(fromDate);

    let dateString1 = value[1];
    let toDate = Date.parse(dateString1);
    // console.log("toDate:");
    // console.log(toDate);

    setfromDateString(fromDate);

    settoDateString(toDate);

    // let dateString1 = value[0];
    // let date = new Date(dateString1);
    // let result1 = date.getTime();
    // console.log(result1);

    // const apis =
    //   `${baseUrl}/api/sensorDatas/biofly/days/7/` + id.deviceIDDetails;

    const apis =
      `${baseUrl}/api/sensorDatas/biofly/datetimerange/869523050076489/` +
      fromDate +
      `/` +
      toDate;

    // /biofly/datetimerange/:deviceID/:fromdate/:todate
    // console.log("apis:", apis);
    // console.log("apis:", apis);

    fetch(apis, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("data:", data);

        setItems(data);
      });
  };

  // const csvData = [
  //   ["firstname", "lastname", "email"],
  //   ["John", "Doe", "john.doe@xyz.com"],
  //   ["Jane", "Doe", "jane.doe@xyz.com"],
  // ];

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="parent">
      {/* <button type="button" class="btn btn-info" onClick={goBack}>
        Back
      </button> */}
      <section className="border text-center mb-4">
        <button type="button" class="btn btn-info" onClick={goBack}>
          Back
        </button>

        <h4 className="mb-3">History Data Table</h4>

        <Stack direction="row" spacing={25} alignItems="flex-start">
          <label>Select a Date range</label>
          <DateRangePicker
            value={value}
            onChange={setValue}
            showMeridian
            format="yyyy-MM-dd HH:mm:ss"
            onExited={checkvalue}
            defaultCalendarValue={[
              new Date("2024-01-01 00:00:00"),
              new Date("2024-02-01 23:59:59"),
            ]}
          />

          {items && (
            <CSVLink
              filename={"Table-data.csv"}
              className="btn btn-primary"
              target="_blank"
              data={items}
            >
              Download table data to CSV
            </CSVLink>
          )}
        </Stack>

        {items && <DynamicTables items={items} />}
      </section>
    </div>
  );
};

export default BioflyDataInTables;
